
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  watch,
  watchEffect,
} from "vue";
import SimpleSelect from "../../components/simpleSelect/index.vue";
import RenderCheckBox from "../../components/renderCheckbox/index.vue";
import CurrencyField from "../../components/currencyField/index.vue";
import {
  optionDepartament,
  optionsEmployment,
  optionsExperience,
  optionCurrencies,
} from "../../constants/jobs";
import Navbar from "@/components/navBarJobBoard/index.vue";
import BoardCard from "../../components/boardCard/index.vue";
import { useRouter } from "vue-router";
import { JobBoardService } from "../../services/jobBoard/index";
import Loader from "@/components/loader/index.vue";
import Paginator from "../../components/paginator/index.vue";
import { useState } from "@/composables/useState";
import SkeletonCard from "../../components/skeletonCard/index.vue";
import { removeBlankProperties } from "@/utils/params";
import RadioButton from "../../components/radioButton/index.vue";
import CustomSearch from "@/components/customSearch/index.vue";
import useClickOutside from "@/composables/useClickOutside";
import { numberWithCommas } from "../../utils/functions";
import PoweredByFooter from "@/components/poweredByFooter/index.vue";

export default defineComponent({
  name: "General Board",
  components: {
    SimpleSelect,
    //RenderCheckBox,
    CurrencyField,
    CustomSearch,
    BoardCard,
    Loader,
    Paginator,
    SkeletonCard,
    RadioButton,
    Navbar,
    PoweredByFooter,
  },
  setup() {
    const jobOpenings = ref([]);
    const router = useRouter();
    const isLoadingPage = ref(true);
    const isLoadingJobs = ref(false);
    const pagination = ref({});
    const [page, setPage] = useState(1);
    const filters = ref({});
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();
    const inputJobValue = ref("");
    const inputLocationValue = ref("");
    const RadioDepartment = ref("");
    const work_type = ref("");
    const rangeSalary = ref([0, 0]);
    const seniority_tab = ref(false);
    const radioSeniority = ref("");
    const remoteJob = ref(false);
    const isFilterClear = ref(false);
    const salaryMin = ref(0);
    const salaryMax = ref(0);
    const salaryError = ref(false);
    const salarySearch = ref("");
    const isFilterbySalary = ref(false);
    const currecySalary = ref("");
    const expand = reactive({
      seniority: false,
      department: false,
      work_type: false,
      salary: false,
    });
    const host = window.location.host,
      domain = host.split(":")[0],
      subdomainComponents = domain.split("."),
      subdomain = subdomainComponents[0];
    const branding = {
      accentColor: "",
      borderAccentColor: "",
    };

    onBeforeMount(() => {
      if (["mtydigitalhub", "mtydigitalhub-test"].includes(subdomain)) {
        let hubBranding = {
          accentColor: "text-color",
          borderAccentColor: "border-color",
        };

        Object.assign(branding, hubBranding);
      }
    });

    onMounted(() => {
      getJobs();
    });

    const getJobs = () => {
      isLoadingJobs.value = true;
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
        department:
          RadioDepartment.value === "all" ? "" : RadioDepartment.value,
        employment_type: work_type.value === "all" ? "" : work_type.value,
        is_remote: remoteJob.value,
        experience: radioSeniority.value === "all" ? "" : radioSeniority.value,
        salary_min: salaryMin.value,
        salary_max: salaryMax.value,
        currency_salary: currecySalary.value,
      });

      if (["mtydigitalhub", "mtydigitalhub-test"].includes(subdomain)) {
        _filters.job_board = "mtydigitalhub"
      }

      JobBoardService.getGeneralJobs(page.value, _filters)
        .then((response: any) => {
          jobOpenings.value = response.data.jobs;
          pagination.value = response.data.meta.pagination;
          isLoadingPage.value = false;
          isLoadingJobs.value = false;
        })
        .catch((error) => {
          isLoadingPage.value = false;
          isLoadingJobs.value = false;
        });
    };

    const updateHandler = (e: any) => {
      setPage(e);
      getJobs();
    };

    const searchByJob = (event: any) => {
      inputJobValue.value = event;
      getJobs();
    };

    const searchByLocation = (event: any) => {
      inputLocationValue.value = event;
      getJobs();
    };

    const isVisibleButton = () => {
      if (
        RadioDepartment.value != "" ||
        work_type.value != "" ||
        radioSeniority.value != ""
      ) {
        isFilterClear.value = true;
      } else {
        isFilterClear.value = false;
      }
    };

    const clearFilters = () => {
      RadioDepartment.value = "";
      work_type.value = "";
      radioSeniority.value = "";
    };

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputJobValue.value
          ? inputJobValue.value
          : inputLocationValue.value,
      });
    });

    const searchBySalary = () => {
      if (salaryMax.value >= salaryMin.value) {
        salaryError.value = false;
        isFilterbySalary.value = true;
        salarySearch.value = `$${numberWithCommas(
          salaryMin.value
        )} - $${numberWithCommas(salaryMax.value)} ${currecySalary.value}`;
        expand.salary = false;
        getJobs();
      } else {
        salaryError.value = true;
      }
    };

    watch(RadioDepartment, (newVal) => {
      setPage(1);
      getJobs();
      isVisibleButton();
    });

    watch(remoteJob, (newVal) => {
      setPage(1);
      getJobs();
      isVisibleButton();
    });

    watch(work_type, (newVal) => {
      setPage(1);
      getJobs();
      isVisibleButton();
    });

    watch(radioSeniority, (newVal) => {
      isVisibleButton();
      setPage(1);
      getJobs();
    });

    const onlyNumber = ($event: any) => {
      //Should be change to a regex expression
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    };

    const clearSalaryRange = () => {
      salaryMin.value = 0;
      salaryMax.value = 0;
      currecySalary.value = "";
      isFilterbySalary.value = false;
      expand.salary = false;
      getJobs();
    };

    return {
      page,
      modal,
      router,
      expand,
      setPage,
      getJobs,
      work_type,
      pagination,
      jobOpenings,
      searchByJob,
      optionDepartament,
      optionsEmployment,
      isLoadingPage,
      updateHandler,
      isLoadingJobs,
      searchByLocation,
      inputJobValue,
      inputLocationValue,
      RadioDepartment,
      seniority_tab,
      optionsExperience,
      radioSeniority,
      remoteJob,
      isVisibleButton,
      isFilterClear,
      clearFilters,
      rangeSalary,
      optionCurrencies,
      searchBySalary,
      salaryMin,
      salaryMax,
      currecySalary,
      isFilterbySalary,
      salarySearch,
      onlyNumber,
      salaryError,
      clearSalaryRange,
      branding,
    };
  },
});
