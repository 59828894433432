
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
  watchEffect,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useState } from "@/composables/useState";
import Loader from "@/components/loader/index.vue";
import { removeBlankProperties } from "@/utils/params";
import SimpleSelect from "../../components/simpleSelect/index.vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import CustomCompany from "../../components/customCompany/index.vue";
import CurrencyField from "../../components/currencyField/index.vue";
import {
  optionDepartament,
  optionsExperience,
  optionsEmployment,
  optionCurrencies,
} from "../../constants/jobs";
import JobCard from "../../components/jobCard/index.vue";
import Paginator from "../../components/paginator/index.vue";
import { JobBoardService } from "../../services/jobBoard/index";
import RadioButton from "../../components/radioButton/index.vue";
import SkeletonCard from "../../components/skeletonCard/index.vue";
import { useStore } from "vuex";
import { useCookies } from "vue3-cookies";
import { hexToRGBA } from "@/utils/functions";
import Navbar from "@/components/navBarJobBoard/index.vue";
import CustomSearch from "@/components/customSearch/index.vue";
import PoweredByFooter from "@/components/poweredByFooter/index.vue";

const { cookies } = useCookies();

export default defineComponent({
  name: "Companies",
  components: {
    SimpleSelect,
    CustomSearch,
    JobCard,
    Paginator,
    Loader,
    RadioButton,
    SkeletonCard,
    CustomCompany,
    ReusableModal,
    Navbar,
    PoweredByFooter,
    CurrencyField,
  },
  setup() {
    const filters = ref({});
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const work_type = ref("");
    const RadioDepartment = ref("");
    const [page, setPage] = useState(1);
    const pagination = ref({});
    const companyJobs = ref([]);
    const openModal = ref(false);
    const inputJobValue = ref("");
    const radioSeniority = ref("");
    const hasJobOpening = ref(false);
    const seniority_tab = ref(false);
    const token = cookies.get("token");
    const inputLocationValue = ref("");
    const [company, setCompany] = useState({});
    const [isLoadingJobs, setLoadingJobs] = useState(true);
    const [isLoadingPage, setLoadingPage] = useState(true);
    const currentRoute = computed(() => router.currentRoute.value.name);
    const remoteJob = ref(false);
    const isFilterClear = ref(false);
    const salaryMin = ref(0);
    const salaryMax = ref(0);
    const salaryError = ref(false);
    const salarySearch = ref("");
    const isFilterbySalary = ref(false);
    const currecySalary = ref("");
    const expand = reactive({
      seniority: false,
      department: false,
      work_type: false,
      salary: false,
    });

    onMounted(() => {
      getCompany();
    });

    const getCompany = () => {
      setLoadingJobs(true);
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
        department:
          RadioDepartment.value === "all" ? "" : RadioDepartment.value,
        employment_type: work_type.value === "all" ? "" : work_type.value,
        is_remote: remoteJob.value,
        experience: radioSeniority.value === "all" ? "" : radioSeniority.value,
        salary_min: salaryMin.value,
        salary_max: salaryMax.value,
        currency_salary: currecySalary.value,
      });
      JobBoardService.getCompany(
        `${route.params.company_name}`,
        page.value,
        _filters
      )
        .then((response: any) => {
          setCompany(response.data.company);
          companyJobs.value = response.data.jobs;
          pagination.value = response.data.meta.pagination;
          setLoadingPage(false);
          setLoadingJobs(false);
        })
        .catch((error) => {
          setLoadingPage(false);
          setLoadingJobs(false);
        });
    };
    const searchByJob = (event: any) => {
      inputJobValue.value = event;
      getCompany();
    };

    const searchByLocation = (event: any) => {
      inputLocationValue.value = event;
      getCompany();
    };

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputJobValue.value
          ? inputJobValue.value
          : inputLocationValue.value,
      });
    });

    watch(remoteJob, (newVal) => {
      setPage(1);
      getCompany();
    });

    watch(RadioDepartment, (newVal) => {
      isVisibleButton();
      setPage(1);
      getCompany();
    });

    watch(work_type, (newVal) => {
      isVisibleButton();
      setPage(1);
      getCompany();
    });

    const updateHandler = (e: any) => {
      setPage(e);
      getCompany();
    };

    watch(radioSeniority, (newVal) => {
      isVisibleButton();
      setPage(1);
      getCompany();
    });

    const updateBranding = () => {
      getCompany();
    };

    const searchBySalary = () => {
      if (Number(salaryMax.value) >= Number(salaryMin.value)) {
        salaryError.value = false;
        isFilterbySalary.value = true;
        salarySearch.value = `$${salaryMin.value} - $${salaryMax.value} ${currecySalary.value}`;
        expand.salary = false;
        getCompany();
      } else {
        salaryError.value = true;
      }
    };

    const onlyNumber = ($event: any) => {
      //Should be change to a regex expression
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    };

    const clearSalaryRange = () => {
      salaryMin.value = 0;
      salaryMax.value = 0;
      currecySalary.value = "";
      isFilterbySalary.value = false;
      expand.salary = false;
      getCompany();
    };

    const isVisibleButton = () => {
      if (
        RadioDepartment.value != "" ||
        work_type.value != "" ||
        radioSeniority.value != ""
      ) {
        isFilterClear.value = true;
      } else {
        isFilterClear.value = false;
      }
    };

    const clearFilters = () => {
      RadioDepartment.value = "";
      work_type.value = "";
      radioSeniority.value = "";
    };

    return {
      page,
      token,
      route,
      isLoadingPage,
      isLoadingJobs,
      getCompany,
      updateHandler,
      filters,
      setPage,
      company,
      searchByJob,
      searchByLocation,
      openModal,
      work_type,
      setCompany,
      RadioDepartment,
      pagination,
      companyJobs,
      inputJobValue,
      inputLocationValue,
      seniority_tab,
      hasJobOpening,
      setLoadingJobs,
      updateBranding,
      radioSeniority,
      optionsExperience,
      optionsEmployment,
      optionDepartament,
      hexToRGBA,
      currentRoute,
      expand,
      remoteJob,
      isVisibleButton,
      isFilterClear,
      clearFilters,
      optionCurrencies,
      salaryMin,
      salaryMax,
      salaryError,
      salarySearch,
      isFilterbySalary,
      currecySalary,
      searchBySalary,
      clearSalaryRange,
      onlyNumber,
    };
  },
});
