
import { defineComponent } from "vue";

export default defineComponent({
  name: "Spinner",
  props: {
    color: {
      type: String,
      default: "",
    },
    border: {
      type: String,
      default: "2",
    },
    height: {
      type: String,
      default: "4",
    },
    width: {
      type: String,
      default: "4",
    },
    top: {
      type: String,
      default: "4",
    },
  },
});
