
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  Ref,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";

export default defineComponent({
  name: "Navbar Job Board",
  setup() {
    const store = useStore();
    const { cookies } = useCookies();
    const route = useRoute();
    const showProfileButton = ref(false);
    const token = cookies.get("token");
    const editCandidateView = `${process.env.VUE_APP_JOB_BOARD_URL}/edit-candidate`;
    const suffix = process.env.VUE_APP_CANARY_SUFFIX;
    const hamburgerMenu: Ref<boolean> = ref(false);
    const profile = computed(() => store.state.profile.data);
    const isTalent = ref(true);
    const host = window.location.host,
      domain = host.split(":")[0],
      subdomainComponents = domain.split("."),
      subdomain = subdomainComponents[0];
    let branding = {
      bgColor: "bg-black",
      burgerBgColor: "bg-primary",
      logo: require("@/assets/axelerate-logo-purple.svg"),
      isWhiteLabel: false,
      accentColor: "",
    };

    onBeforeMount(async () => {
      if (token) {
        await store.dispatch("getProfile");
      }

      if (
        route.name === "General Board" ||
        route.name === "Companies" ||
        route.name === "Company" ||
        route.name === "Job Page"
      ) {
        showProfileButton.value = true;
        isTalent.value = profile?.value?.roles?.includes("talent")
          ? true
          : false;
      }

      if (["mtydigitalhub", "mtydigitalhub-test"].includes(subdomain)) {
        let hubBranding = {
          bgColor: "branding-background",
          burgerBgColor: "accent-color",
          logo: require("@/assets/dhm-logo.png"),
          isWhiteLabel: true,
          accentColor: "accent-color",
        };

        Object.assign(branding, hubBranding);
      }

      let intercomScript = document.createElement("script");
      intercomScript.text = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.VUE_APP_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

      document.head.appendChild(intercomScript);

      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
      });
    });

    const logout = () => {
      store.dispatch("logOutJSE");
    };

    return {
      logout,
      token,
      editCandidateView,
      suffix,
      hamburgerMenu,
      branding,
      showProfileButton,
      isTalent,
    };
  },
});
