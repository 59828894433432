
import { defineComponent } from "vue";
import { useRouter } from "vue-router";
import { numberWithCommas } from "../../utils/functions";

const JobCard = defineComponent({
  name: "JobCard",
  components: {},
  props: {
    company: {
      type: String,
      required: true,
    },
    jobs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const router = useRouter();

    /** NOTE: Route changes depending if we're in company board or JSE  */
    const goToJob = (company: string, job: any) => {
      if (router.currentRoute.value.name == "Company Board") {
        router.push(`/${company}/jobs/${job.id}`);
      } else {
        router.push(`/companies/${company}/jobs/${job.id}`);
      }
    };

    return {
      router,
      numberWithCommas,
      goToJob,
    };
  },
});

export default JobCard;
