
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useState } from "@/composables/useState";
import Loader from "@/components/loader/index.vue";
import { numberWithCommas, hexToRGBA } from "../../utils/functions";
import useClickOutside from "@/composables/useClickOutside";
import { JobBoardService } from "../../services/jobBoard/index";
import SimilarJobs from "../../components/similarJobs/index.vue";
import ApplyJobForm from "../../components/applyJobForm/index.vue";
import SuccessApply from "../../components/successApply/index.vue";
import useClipboard from "../../composables/useClipboard";
import { createToastAxelerate } from "@/utils/toast";
import Navbar from "@/components/navBarJobBoard/index.vue";
import PoweredByFooter from "@/components/poweredByFooter/index.vue";
import { useCookies } from "vue3-cookies";
import BaseModal from "@/components/baseModal/index.vue";
import { usePromisedModal } from "@/composables/usePromisedModal";
import { useStore } from "vuex";

export default defineComponent({
  name: "Job Page",
  components: {
    Loader,
    SimilarJobs,
    ApplyJobForm,
    SuccessApply,
    Navbar,
    PoweredByFooter,
    BaseModal,
  },
  setup() {
    const job = ref();
    const { toClipboard } = useClipboard();
    const modal = ref(null);
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const textButton = ref("");
    const similarJobs = ref([]);
    const showModal = ref(false);
    const isClicked = ref(false);
    const savingJob = ref(false);
    const isMenuOpen = ref(false);
    const { cookies } = useCookies();
    const disabledButton = ref(false);
    const modalText = ref("");
    const modalButtonText = ref("");
    const token = cookies.get("token");
    const isLoadingButton = ref(false);
    const { onClickOutside } = useClickOutside();
    const step = computed(() => route.params.step);
    const jobId = computed(() => route.params.job_id);
    const confirmationModal = usePromisedModal<boolean>();
    const [isLoadingPage, setLoadingPage] = useState(true);
    const [requiredSkills, setRequiredSkills] = useState([]);
    const company = computed(() => route.params.company_name);
    const [niceToHaveSkills, setNiceToHaveSkills] = useState([]);
    const [stepApplication, setSteepApplication] = useState("jobInfo");
    const candidateId = computed(() => store.state.profile.data.candidate.id);
    const isProfileFilled = computed(
      () => store.state.profile.data.has_complete_profile
    );

    const companyDomain = `${process.env.VUE_APP_JOB_BOARD_URL}/companies/${company.value}/jobs/${jobId.value}`;
    const branding = {
      accentColor: "bg-purple-300 bg-primary",
    };
    store.dispatch("setPreviousUrl", null);
    const host = window.location.host,
      domain = host.split(":")[0],
      subdomainComponents = domain.split("."),
      subdomain = subdomainComponents[0];

    onClickOutside(modal, () => {
      if (isMenuOpen.value === true) {
        isMenuOpen.value = false;
      }
    });

    onBeforeMount(() => {
      if (["mtydigitalhub", "mtydigitalhub-test"].includes(subdomain)) {
        let hubBranding = {
          accentColor: "accent-color",
        };

        Object.assign(branding, hubBranding);
      }
    });

    onMounted(() => {
      window.scrollTo(0, 0);
      setTab();
      changeState();
      getJob();
    });

    const setTab = () => {
      if (!step.value) {
        router.replace({
          params: { step: "job-info" },
        });
      } else {
        router.replace({
          params: { step: step.value },
        });
      }
    };

    const getJob = () => {
      setLoadingPage(true);
      JobBoardService.getJobByCompany(
        String(company.value),
        String(jobId.value)
      )
        .then((response: any) => {
          const mapObjToName = ({ name }: { id: string; name: string }) => {
              return name;
            },
            requiredSkills = response.data.required_skills.map(mapObjToName),
            niceToHaveSkills = response.data.optional_skills.map(mapObjToName);

          job.value = response.data;
          setRequiredSkills(
            requiredSkills.length > 0 ? requiredSkills : response.data.keywords
          );
          setNiceToHaveSkills(niceToHaveSkills);
          setLoadingPage(false);
        })
        .catch(() => {
          setLoadingPage(false);
        });
    };

    watch(jobId, (newVal) => {
      getJob();
    });

    watch(step, (newVal) => {
      setTab();
    });

    const changeState = () => {
      switch (stepApplication.value) {
        case "jobInfo":
          disabledButton.value = false;
          isLoadingButton.value = false;
          setSteepApplication("jobInfo");
          textButton.value = "Apply now";
          router.replace({
            params: { step: "job-info" },
          });
          break;
        case "jobForm":
          disabledButton.value = true;
          isLoadingButton.value = false;
          setSteepApplication("jobForm");
          window.scrollTo(0, 0);
          textButton.value = "Submit application";
          router.replace({
            params: { step: "application" },
          });
          break;
        case "jobSuccess":
          disabledButton.value = false;
          isLoadingButton.value = false;
          textButton.value = "Go to job openings";
          router.replace({
            params: { step: "application" },
          });
          break;
        default:
          setSteepApplication("jobInfo");
          textButton.value = "Apply now";
          break;
      }
    };

    watch(stepApplication, (newVal) => {
      changeState();
    });

    const isValidForm = (form: boolean) => {
      disabledButton.value = !form;
    };

    const isSavingJob = (_isLoading: boolean) => {
      textButton.value = _isLoading ? "Applying to job" : "Submit application";
      isLoadingButton.value = _isLoading;
      isClicked.value = false;
    };

    const shareJob = async () => {
      try {
        await toClipboard(`${companyDomain}`);
        createToastAxelerate(
          "Share job",
          "Copied to clipboard",
          "info",
          () => undefined,
          2000,
          "bottom-center",
          "#00000f"
        );
      } catch (e) {
        createToastAxelerate("Share job", "An error has ocurred", "danger");
      }
    };

    const actionButton = () => {
      switch (stepApplication.value) {
        case "jobInfo":
          if (token) {
            showAlert();
          } else {
            setSteepApplication("jobForm");
          }
          break;
        case "jobForm":
          isClicked.value = true;
          break;
        case "jobSuccess":
          router.push("/");
          break;
      }
    };

    async function showAlert() {
      showModal.value = true;
      if (isProfileFilled.value) {
        modalButtonText.value = "Apply";
        modalText.value = `Are you sure you want to apply to <strong>${job.value.title}</strong> position?`;
        const confirmed = await confirmationModal.ask();
        if (confirmed) {
          applyToJob();
        } else {
          showModal.value = false;
        }
      } else {
        modalButtonText.value = "Complete my profile";
        modalText.value = `Some required fields on your profile are missing`;
        const confirmed = await confirmationModal.ask();
        if (confirmed) {
          store.dispatch(
            "setPreviousUrl",
            `companies/${company.value}/jobs/${jobId.value}`
          );
          router.push("/edit-candidate");
        } else {
          showModal.value = false;
        }
      }
    }

    const applyToJob = () => {
      savingJob.value = true;
      JobBoardService.applyJobSignedTalent(
        company.value.toString(),
        jobId.value.toString(),
        candidateId.value
      )
        .then((response: any) => {
          showModal.value = false;
          jobApplied();
          savingJob.value = false;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Apply Job",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
          savingJob.value = false;
          showModal.value = false;
        });
    };

    const backButton = () => {
      switch (stepApplication.value) {
        case "jobInfo":
          router.back();
          break;
        case "jobForm":
          setSteepApplication("jobInfo");
          break;
      }
    };

    const jobApplied = () => {
      window.scrollTo(0, 0);
      setSteepApplication("jobSuccess");
    };

    return {
      job,
      step,
      modal,
      jobId,
      router,
      getJob,
      company,
      branding,
      shareJob,
      modalText,
      savingJob,
      isClicked,
      showModal,
      hexToRGBA,
      isMenuOpen,
      textButton,
      backButton,
      jobApplied,
      similarJobs,
      isSavingJob,
      isValidForm,
      actionButton,
      isLoadingPage,
      requiredSkills,
      disabledButton,
      setLoadingPage,
      modalButtonText,
      stepApplication,
      isLoadingButton,
      niceToHaveSkills,
      numberWithCommas,
      confirmationModal,
      setSteepApplication,
    };
  },
});
