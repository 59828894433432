
import { defineComponent } from "vue";

const SkeletonCard = defineComponent({
  name: "SkeletonCard",
  props: {
    type: {
      type: String,
      default: "job",
    },
  },
});
export default SkeletonCard;
