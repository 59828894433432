
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import useClipboard from "../../composables/useClipboard";
import { createToastAxelerate } from "../../utils/toast";
import { JobBoardService } from "../../services/jobBoard/index";
import ReusableModal from "../../components/reusableModal/index.vue";
import CustomCompany from "../../components/customCompany/index.vue";
import { hexToRGBA } from "@/utils/functions";
import Loader from "@/components/loader/index.vue";
import moment from "moment";

const BoardConfig = defineComponent({
  name: "BoardConfig",
  components: {
    ReusableModal,
    CustomCompany,
    Loader,
  },
  setup() {
    const { toClipboard } = useClipboard();
    const store = useStore();
    const companyId = computed(() => store.state.profile.data.company.id);
    const companyDomain = ref("");
    const isLoading = ref(true);
    const openModal = ref(false);
    const company = ref({});

    const copy = async () => {
      try {
        await toClipboard(`${companyDomain.value}`);
        createToastAxelerate(
          "Share job board",
          "Copied to clipboard",
          "info",
          () => undefined,
          2000,
          "bottom-center",
          "#00000f"
        );
      } catch (e) {
        createToastAxelerate(
          "Share job board",
          "An error has ocurred",
          "danger"
        );
      }
    };

    onMounted(() => {
      getCompanyBranding();
    });

    const getCompanyBranding = () => {
      isLoading.value = true;
      JobBoardService.getCompanyById(companyId.value)
        .then((response: any) => {
          let domain = response?.data.company?.company_branding?.domain_name;
          company.value = response.data.company;
          companyDomain.value = `${process.env.VUE_APP_JOB_BOARD_URL}/${domain}`;
          isLoading.value = false;
        })
        .catch((error) => {
          isLoading.value = false;
        });
    };

    const updateBranding = () => {
      getCompanyBranding();
    };

    const timeAgo = (date: any) => {
      return moment(date).fromNow();
    };

    return {
      copy,
      companyDomain,
      isLoading,
      openModal,
      company,
      updateBranding,
      hexToRGBA,
      timeAgo,
    };
  },
});
export default BoardConfig;
