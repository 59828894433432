
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

const SettingsCompanySubscription = defineComponent({
  name: "SettingsCompanySubscription",
  components: {},
  setup() {
    const store = useStore();
    const data = computed(
      () => store.state.profile.data.company?.last_subscription
    );
    return { data };
  },
});

export default SettingsCompanySubscription;
