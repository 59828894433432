import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10b6304f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative z-0 w-full mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Field, {
      as: "textarea",
      value: _ctx.modelValue,
      name: _ctx.name,
      disabled: _ctx.disabled,
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      placeholder: _ctx.placeholder,
      autocomplete: "on",
      rows: _ctx.rows,
      cols: _ctx.cols,
      class: _normalizeClass(_ctx.className)
    }, null, 8, ["value", "name", "disabled", "placeholder", "rows", "cols", "class"]),
    _createVNode(_component_ErrorMessage, {
      name: _ctx.name,
      class: "text-sm pl-2",
      style: {"color":"#ff3333"},
      id: "error"
    }, null, 8, ["name"])
  ]))
}