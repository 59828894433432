
import { ref, onMounted, onBeforeMount, computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { loadScript } from "vue-plugin-load-script";
import { createToastAxelerate } from "../../utils/toast";
import { nylasAPI } from "../../services/nylas/index";

export default defineComponent({
  name: "Nylas Settings",
  setup() {
    const teams = ref({
      is_linked: false,
      account_id: null,
    });
    const route = useRoute(),
      store = useStore(),
      nylasAccessToken = computed(() => store.state.nylas.accessToken),
      isCalendarLinked = computed(() => store.state.nylas.isCalendarLinked),
      provider = computed(() => store.state.nylas.provider),
      connectCalendar = (provider: any) => {
        const oauthURL = new URL("https://api.nylas.com/oauth/authorize"),
          queryParams = oauthURL.searchParams;

        queryParams.set("client_id", process.env.VUE_APP_NYLAS_CLIENT_ID);
        queryParams.set(
          "redirect_uri",
          process.env.VUE_APP_NYLAS_AUTHORIZATION_REDIRECT_URL
        );
        queryParams.set("response_type", "token");
        queryParams.set("scopes", "email.send,email.read_only,calendar")

        if (provider) {
          queryParams.set("provider", provider);
        }

        oauthURL.search = queryParams.toString();
        window.location.href = oauthURL.toString();
      },
      viewSchedulingPages = async () => {
        await loadScript(
          "https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js"
        );

        (window as any).nylas.scheduler.show({
          auth: {
            accessToken: store.state.nylas.accessToken,
          },
          style: {
            tintColor: "#32325d",
            backgroundColor: "white",
          },
          defaults: {
            event: {
              title: "Screening with Axented",
              duration: 30,
            },
          },
        });
      };

    async function linkTeams(body: any) {
      nylasAPI.saveTeamsInfo(body).then(() => {
        store.state.nylas.isTeamsLinked = true;
      });
    }

    async function getLinkedStatus() {
      const data = await nylasAPI.getLinkStatus();
      teams.value = data.data.teams;
    }

    async function getLinkedURL() {
      const { data } = await nylasAPI.getLinkUrl(),
            { url } = data

      window.open(url)
    }

    onBeforeMount(() => {
      const urlNylasAccessToken = route.query.access_token;
      const provider = route.query.provider;

      if (urlNylasAccessToken) {
        /** TODO(Rodolfo Islas): Delete sensitive data from URL */
        const payload = {
          nylas: {
            access_token: urlNylasAccessToken,
            provider: provider,
          },
        };

        /** TODO(Rodolfo Islas): Change to use store.dispatch() */
        nylasAPI
          .registerNylas(payload as any)
          .then(() => {
            store.state.nylas.accessToken = urlNylasAccessToken;
            store.state.nylas.isCalendarLinked = true;
          })
          .catch(() => {
            createToastAxelerate(
              "Link calendar",
              "An error has ocurred",
              "danger"
            );
          });
      } else if (provider) {
        const isLinked = route.query.success;
        const grant = route.query.grant_id;
        teams.value.is_linked = isLinked as any;
        teams.value.account_id = grant as any;
        const body = {
          teams: teams.value,
        };
        linkTeams(body);
      }
    });

    onMounted(() => {
      const urlNylasAccessToken = route.query.access_token;

      getLinkedStatus();

      if (!urlNylasAccessToken) {
        store.dispatch("retrieveAccessToken");
      }
    });

    return {
      connectCalendar,
      isCalendarLinked,
      nylasAccessToken,
      viewSchedulingPages,
      getLinkedURL,
      teams,
      linkTeams,
      provider,
    };
  },
});
