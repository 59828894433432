
import { defineComponent, onMounted, ref } from "vue";
import TextField from "../textField/index.vue";
import PasswordField from "../../components/passwordField/index.vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { ForgotPasswordAPI } from "@/services/forgotPassword";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { userAPI } from "@/services/user/index";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextField,
    PasswordField,
  },
  emits: ["update:openModal"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const isLoading = ref(false);
    const { value: password_confirmation } = useField("password_confirmation");
    const { value: password } = useField("password");
    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: email } = useField("email");

    const schema = yup.object({
      first_name: yup.string().required(),
      last_name: yup.string().required(),
      email: yup.string().required().email(),
      password: yup
        .string()
        .required()
        .min(8)
        .matches(
          /[0-9A-Za-z]*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?][0-9a-zA-Z]*$/,
          "Must contain at least one uppercase, special character and number"
        ),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    });

    const { handleSubmit, setValues } = useForm({
      validationSchema: schema,
    });

    const onSubmit = handleSubmit((values) => {
      isLoading.value = true;
      const body = {
        talent: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          password_confirmation: values.password_confirmation,
        },
      };
      userAPI
        .signUpTalent(body)
        .then(async () => {
          const loginData = {
            email: values.email,
            password: values.password,
          };
          await store.dispatch("loginUser", loginData);
          router.push("/candidate-overview");
        })
        .catch((err) => {
          let txt = null;
          const {
            response: {
              data: { errors },
            },
          } = err;
          if (errors?.Email) {
            txt = "Email " + errors.Email[0];
          }
          createToastAxelerate(
            "Something went wrong",
            (txt = txt ? txt : "Please try again"),
            "danger"
          );
          isLoading.value = false;
        });
    });

    function closeModal() {
      emit("update:openModal", false);
    }

    onMounted(() => {
      const email = store.getters.getOnboardingEmail;
      const first_name = store.getters.getOnboardingFirstName;
      const last_name = store.getters.getOnboardingLastName;
      setValues({
        email: email,
        first_name: first_name,
        last_name: last_name,
      });
    });

    return {
      isLoading,
      email,
      first_name,
      last_name,
      password,
      password_confirmation,
      closeModal,
      onSubmit,
    };
  },
});
