import { Module } from "vuex";
import { RootState } from "../types";
import { UserState } from "./types";
import { getters } from "./getter";
import { actions } from "./actions";
import { mutations } from "./mutations";

const state: UserState = {
  email: "",
  first_name: "",
  last_name: "",
  user_id: "",
  role: "",
  company_id: "",
  company_name: "",
  products: [],
  payments: [],
  onboardingEmail: "",
  previousUrl: "",
  onboardingFirstName: "",
  onboardingLastName: "",
  candidateId: "",
  goToOnboarding: false,
};

export const user: Module<UserState, RootState> = {
  state,
  getters,
  actions,
  mutations,
};
