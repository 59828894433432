/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosInstance } from "axios";
import { client } from "../config";
import { talent } from "../config";
import { User, Recover, SetPassword, ChangePassword } from "./types";

export default class UserAPIService {
  private axiosInstance: AxiosInstance;
  private axiosInstanceTalent: AxiosInstance;

  constructor() {
    this.axiosInstance = client;
    this.axiosInstanceTalent = talent;
  }

  async signUp(user: Partial<User>): Promise<unknown> {
    return this.axiosInstance.post("/sign_up", user);
  }
  async getUserRegister(token: string): Promise<unknown> {
    return this.axiosInstance.get(`/user/${token}`);
  }
  async updateSignUp(token: any, payload: any): Promise<unknown> {
    return this.axiosInstance.put(`/account_confirmation/${token}`, payload);
  }
  async recoverPassword(user: Partial<Recover>): Promise<unknown> {
    return this.axiosInstance.post("/recover_password", user);
  }
  async resetPassword(data: Partial<SetPassword>): Promise<unknown> {
    return this.axiosInstance.put("/set_password", data);
  }
  async getProfile(): Promise<unknown> {
    return this.axiosInstance.get("/profiles");
  }

  async updateUser(data: any, id: number | string): Promise<unknown> {
    const formData = new FormData();
    formData.append("user[first_name]", data.first_name);
    formData.append("user[last_name]", data.last_name);
    if (data.avatar) formData.append("user[avatar]", data.avatar);
    return this.axiosInstance.put(`/users/${id}`, formData);
  }

  async getUsers(): Promise<unknown> {
    return this.axiosInstance.get("/users");
  }
  async updateToken(id: any): Promise<unknown> {
    return this.axiosInstance.put(`/account_confirmation/${id}`);
  }
  async emailConfirmation(id: any): Promise<unknown> {
    return this.axiosInstance.put(`/activate_user/${id}`);
  }
  async changePassword(id: string, payload: ChangePassword): Promise<unknown> {
    return this.axiosInstance.put(`/users/${id}/change_password`, payload);
  }
  async resendEmailConfirmation(id: string): Promise<unknown> {
    return this.axiosInstance.post(`/resend_email_confirmation/${id}`);
  }
  async getCompanyIndustries(): Promise<unknown> {
    return this.axiosInstance.get("/company_industries");
  }
  async updateCompanyInfo(companyId: string, data: any): Promise<unknown> {
    return this.axiosInstance.put(`/companies/${companyId}`, data);
  }
  async signUpTalent(data: any): Promise<unknown> {
    return this.axiosInstanceTalent.post("/talents/sign_up", data);
  }
  async updateTalent(id: string, data: any): Promise<unknown> {
    const candidate = data.candidate;
    const formData = new FormData();
    formData.append("candidate[address]", candidate.address);
    formData.append("candidate[first_name]", candidate.first_name);
    formData.append("candidate[last_name]", candidate.last_name);
    if (candidate.current_salary) {
      formData.append("candidate[current_salary]", candidate.current_salary);
    }
    if (candidate.salary_currency) {
      formData.append(
        "candidate[current_salary_currency]",
        candidate.salary_currency
      );
    }
    formData.append("candidate[email]", candidate.email);
    formData.append("candidate[place_id]", candidate.place_id);

    formData.append("candidate[interests]", candidate.interests);
    formData.append("candidate[link]", candidate.link);
    formData.append("candidate[phone]", candidate.phone);
    if (candidate.resume)
      formData.append("candidate[resume]", candidate.resume);
    if (candidate.documents) {
      formData.append("candidate[documents]", candidate.documents);
    }
    if (candidate.salary_currency) {
      formData.append("candidate[salary_currency]", candidate.salary_currency);
    }
    formData.append("candidate[salary_expectation]", candidate.current_salary);
    formData.append("candidate[summary]", candidate.summary);

    return this.axiosInstanceTalent.put(`/candidates/${id}`, formData);
  }
  async updateTalentHistory(id: string, data: any): Promise<unknown> {
    return this.axiosInstanceTalent.put(`/candidates/${id}`, data);
  }

  async checkMail(email: string): Promise<unknown> {
    return this.axiosInstanceTalent.get(`/talents/check_email?email=${email}`);
  }

  async getTalent(id: string): Promise<unknown> {
    return this.axiosInstanceTalent.get(`/candidates/${id}`);
  }
}

export const userAPI = new UserAPIService();
