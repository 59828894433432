import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-auto-fit gap-4" }
const _hoisted_2 = { class: "grid-item" }
const _hoisted_3 = { class: "grid-item" }
const _hoisted_4 = { class: "w-full rounded-2xl p-7 bg-white mt-4" }
const _hoisted_5 = { class: "w-full rounded-2xl p-7 bg-white mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NextBilling = _resolveComponent("NextBilling")!
  const _component_AccountPaymentMethods = _resolveComponent("AccountPaymentMethods")!
  const _component_AccountBillingTable = _resolveComponent("AccountBillingTable")!
  const _component_PaymentContacts = _resolveComponent("PaymentContacts")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_NextBilling)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AccountPaymentMethods)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AccountBillingTable)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_PaymentContacts)
    ])
  ], 64))
}