
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import Datepicker from "@vuepic/vue-datepicker";
import "./styles.css";

const CustomDatePicker = defineComponent({
  name: "CustomDatePicker",
  components: {
    Field,
    ErrorMessage,
    Datepicker,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    minDate: {
      type: String,
      default: "",
    },
  },
  setup() {
    const format = (date: any) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };

    return {
      format,
    };
  },
});
export default CustomDatePicker;
