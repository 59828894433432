import { ActionTree } from "vuex";
import { RootState } from "../types";
import { UserState } from "./types";
import { getProfile } from "@/services/billing";

export const actions: ActionTree<UserState, RootState> = {
  async getProfile({ commit }) {
    const data = await getProfile();
    if (data) {
      commit("SET_PAYMENTS", data.company?.payment_methods?.data);
    }
  },
  setOnboardingFirstName({ commit }, first_name: string) {
    commit("SET_ONBOARDINGFIRSTNAME", first_name);
  },
  setOnboardingLastName({ commit }, last_name: string) {
    commit("SET_ONBOARDINGLASTNAME", last_name);
  },
  setOnboardingEmail({ commit }, email: string) {
    commit("SET_ONBOARDINGEMAIL", email);
  },
  setPreviousUrl({ commit }, url: string) {
    commit("SET_PREVIOUSURL", url);
  },
};
