import {
  createRouter,
  createWebHistory,
  Router,
  RouteRecordRaw,
} from "vue-router";
import { useCookies } from "vue3-cookies";
import store from "@/store";
import Home from "../views/home/index.vue";
import Login from "../views/login/index.vue";
import CompleteAccount from "../views/complete-account/index.vue";
import ForgotPassword from "../views/forgot-password/index.vue";
import ResetPassword from "../views/reset-password/index.vue";
import AccountSettings from "../views/account-settings/index.vue";
import ApplyJob from "../views/apply-job/index.vue";
import JobDetails from "../views/job-details/index.vue";
import SignUp from "../views/sign-up/index.vue";
import EmailConfirmation from "../views/email-confirmation/index.vue";
// import Pricing from "../views/pricing/index.vue";
import SuccessPayment from "../views/success-payment/index.vue";
import CancelPayment from "../views/cancel-payment/index.vue";
import GeneralBoard from "../views/general-board/index.vue";
import Companies from "../views/companies/index.vue";
import Company from "../views/company/index.vue";
import JobPage from "../views/job-page/index.vue";
import CandidateOnboarding from "../views/candidateOnboarding/index.vue";
import CandidateEdit from "../views/edit-candidate/index.vue";
import CandidateOverview from "../views/talent-overview/index.vue";
import Error404 from "../views/404-error/index.vue";
import Error500 from "../views/500-error/index.vue";

const { cookies } = useCookies();

const host = window.location.host,
  domain = host.split(":")[0],
  idRoutes: Array<RouteRecordRaw> = [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/complete-account",
      name: "CompleteAccount",
      component: CompleteAccount,
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/reset-password",
      name: "Reset Password",
      component: ResetPassword,
      props: (route) => ({ token: route.query.token }),
    },
    {
      path: "/account-settings/:tab?",
      name: "Account settings",
      component: AccountSettings,
    },
    {
      path: "/sign-up/:accountType",
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: "/email-confirmation/:token",
      name: "Email Confirmation",
      component: EmailConfirmation,
    },
    /* {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    }, */
    {
      path: "/success-payment",
      name: "Success Payment",
      component: SuccessPayment,
    },
    {
      path: "/cancel-payment",
      name: "Cancel Payment",
      component: CancelPayment,
    },
  ],
  boardRoutes: Array<RouteRecordRaw> = [
    {
      path: "/:company_name",
      name: "Company Board",
      component: Company,
      children: [
        {
          path: "",
          name: "Company Board",
          component: Company,
          alias: ["/:company_name"],
        },
      ],
    },
    {
      path: "/apply-job/:company_name/:job_id",
      name: "Details",
      component: JobDetails,
      children: [
        {
          path: "",
          name: "Details",
          component: JobDetails,
          alias: ["/:company_name/jobs/:job_id"],
        },
      ],
    },
  ],
  jseRoutes: Array<RouteRecordRaw> = [
    {
      path: "/",
      name: "General Board",
      component: GeneralBoard,
    },
    {
      path: "/companies",
      name: "Companies",
      component: Companies,
    },
    {
      path: "/companies/:company_name",
      name: "Company",
      component: Company,
    },
    {
      path: "/companies/:company_name/jobs/:job_id",
      name: "Job Page",
      component: JobPage,
    },
    {
      path: "/not-found",
      name: "404 Error",
      component: Error404,
    },
    {
      path: "/internal-server-error",
      name: "500 Error",
      component: Error500,
    },
    {
      path: "/candidate-onboarding/:idCandidate",
      name: "CandidateOnboarding",
      component: CandidateOnboarding,
    },
    {
      path: "/edit-candidate/:tab?",
      name: "Edit Candidate",
      component: CandidateEdit,
    },
    {
      path: "/candidate-overview",
      name: "Candidate Overview",
      component: CandidateOverview,
    },
  ],
  routes: Array<RouteRecordRaw> = [];

if (
  ["mtydigitalhub.axelerate.io", "mtydigitalhub-test.axelerate.io"].includes(
    domain
  )
) {
  /** Whitelabe JSE */
  routes.push(...jseRoutes);
} else {
  /** JSE and Company Job Board */
  switch (domain) {
    case "localhost":
      idRoutes.splice(0, 1);

      routes.push(...idRoutes);
      routes.push(...boardRoutes);
      routes.push(...jseRoutes);

      break;
    case "id.axelerate.io":
    case "id-test.axelerate.io":
      routes.push(...idRoutes);

      break;
    case "careers.axelerate.io":
    case "careers-test.axelerate.io":
      routes.push(...boardRoutes);

      break;
    case "axelerate.io":
    case "www.axelerate.io":
    case "test.axelerate.io":
      routes.push(...jseRoutes);

      break;
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const jseRedirectionRules = (to: any, from: any, next: any) => {
  next();
};

const localRedirectionRules = (to: any, from: any, next: any) => {
  const token = cookies.get("token");
  const route = router.resolve({ path: to.fullPath });
  const absoluteURL = new URL(route.href, window.location.origin).href;

  const loginPaths = [
    "Login",
    "CompleteAccount",
    "ForgotPassword",
    "Reset Password",
    "Sign Up",
    "Email Confirmation",
  ];
  const publicPaths = [
    "Apply Job",
    "Details",
    "General Board",
    "Companies",
    "Company",
    "Job Page",
    "Company Board",
    "CandidateOnboarding",
    "404 Error",
    "500 Error",
  ];

  if (publicPaths.includes(to.name as string)) {
    next();
  } else if (!loginPaths.includes(to.name as string) && !token) {
    next({ path: "/login", query: { redirect_path: absoluteURL } });
  } else if (loginPaths.includes(to.name as string) && token) {
    next({ path: "/" });
  } else {
    next();
  }
};

const idRedirectionRules = (to: any, from: any, next: any) => {
  const token = cookies.get("token");
  const route = router.resolve({ path: to.fullPath });
  const absoluteURL = new URL(route.href, window.location.origin).href;

  const loginPaths = [
    "Login",
    "CompleteAccount",
    "ForgotPassword",
    "Reset Password",
    "Sign Up",
    "Email Confirmation",
  ];

  if (!loginPaths.includes(to.name as string) && !token) {
    next({ path: "/login", query: { redirect_path: absoluteURL } });
  } else if (loginPaths.includes(to.name as string) && token) {
    next({ path: "/" });
  } else {
    next();
  }
};

const careersRedirectionRules = (to: any, from: any, next: any) => {
  next();
};

router.beforeEach((to, from, next) => {
  if (
    ["mtydigitalhub.axelerate.io", "mtydigitalhub-test.axelerate.io"].includes(
      domain
    )
  ) {
    /** Whitelabe JSE */
    jseRedirectionRules(to, from, next);
  } else {
    /** JSE and Company Job Board */
    switch (domain) {
      case "localhost":
        localRedirectionRules(to, from, next);

        break;
      case "id.axelerate.io":
      case "id-test.axelerate.io":
        idRedirectionRules(to, from, next);

        break;
      case "careers.axelerate.io":
      case "careers-test.axelerate.io":
        careersRedirectionRules(to, from, next);

        break;
      case "axelerate.io":
      case "www.axelerate.io":
      case "test.axelerate.io":
        jseRedirectionRules(to, from, next);

        break;
    }
  }
});

export default router;
