
import { defineComponent } from "vue";
import Navbar from "@/components/navBarJobBoard/index.vue";
import ApplyJobForm from "@/components/applyJobForm/index.vue";

export default defineComponent({
  components: {
    ApplyJobForm,
    Navbar,
  },
  setup() {
    return {};
  },
});
