
import { computed, defineComponent, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import TextField from "@/components/elements/textField/index.vue";
import Spinner from "@/components/elements/spinner/index.vue";
import { useField, useForm } from "vee-validate";
import * as yup from "yup";

export default defineComponent({
  name: "Login",
  components: {
    TextField,
    Spinner,
  },
  setup() {
    //Variables
    const store = useStore();
    const rememberMe = ref(false);
    const route = useRoute();
    const schema = yup.object({
      email: yup.string().required().email().label("Email"),
      password: yup.string().required().label("Password"),
    });
    const redirectPath = ref("");
    const fieldErrors = ref([]);

    //Validation
    const { handleSubmit } = useForm({
      validationSchema: schema,
    });

    const { value: email } = useField("email");
    const { value: password } = useField("password");

    const onSubmit = handleSubmit((values) => {
      const user = {
        email: values.email,
        password: values.password,
        redirect_path: redirectPath.value,
      };
      fieldErrors.value = [];
      store.dispatch("loginUser", user);
    }, onInvalidSubmit);

    //Functions
    function onInvalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }

    //Hooks
    const isLoading = computed(() => store.getters.getLoading);
    const loginError = computed(() => store.getters.getLoginError);
    onMounted(() => {
      if (route.query.redirect_path) {
        redirectPath.value = String(route.query.redirect_path);
      }

      store.dispatch("setLoginError", false);
      let intercomScript = document.createElement("script");
      intercomScript.text = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.VUE_APP_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

      document.head.appendChild(intercomScript);

      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
      });
    });

    return {
      email,
      password,
      rememberMe,
      fieldErrors,
      isLoading,
      loginError,
      onSubmit,
    };
  },
});
