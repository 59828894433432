
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import SaveInfoModal from "@/components/saveInfoModal/index.vue";
import { useCookies } from "vue3-cookies";
import { userAPI } from "@/services/user/index";

const SuccessAply = defineComponent({
  name: "SuccessAply",
  components: {
    SaveInfoModal,
  },
  setup() {
    const openModal = ref(false);
    const store = useStore();
    const email = store.getters.getOnboardingEmail;
    const emailTaken = ref(false);
    const isLoadingText = ref(true);
    const { cookies } = useCookies();
    const token = cookies.get("token");
    const suffix = process.env.VUE_APP_CANARY_SUFFIX;

    onMounted(() => {
      isLoadingText.value = true;
      checkEmail();
    });

    const checkEmail = () => {
      userAPI
        .checkMail(email)
        .then((response) => {
          isLoadingText.value = false;
          emailTaken.value = false;
        })
        .catch((error) => {
          isLoadingText.value = false;
          emailTaken.value = true;
        });
    };

    return {
      token,
      openModal,
      isLoadingText,
      emailTaken,
      suffix,
    };
  },
});
export default SuccessAply;
