import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '../../assets/global-board/icons/search-gray.svg'
import _imports_1 from '../../assets/global-board/icons/pin-gray.svg'


const _hoisted_1 = { class: "flex flex-row items-center w-full bg-white rounded-lg" }
const _hoisted_2 = { class: "flex flex-row py-4 w-full" }
const _hoisted_3 = {
  key: 0,
  src: _imports_0,
  class: "mx-2"
}
const _hoisted_4 = {
  key: 1,
  src: _imports_1,
  class: "mx-2"
}
const _hoisted_5 = ["name", "value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon === 'search')
        ? (_openBlock(), _createElementBlock("img", _hoisted_3))
        : (_openBlock(), _createElementBlock("img", _hoisted_4)),
      _createElementVNode("input", {
        type: "text",
        name: _ctx.name,
        value: _ctx.modelValue,
        onKeyup: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.search && _ctx.search(...args))),
        placeholder: _ctx.placeholder,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        class: "font-medium apperance-none border-0 rounded-2xl w-full px-1 focus:outline-none focus:ring-0 focus:ring-black focus:bg-white"
      }, null, 40, _hoisted_5)
    ])
  ]))
}