import { MutationTree } from "vuex";
import { UserProfile } from "./types";

export const mutations: MutationTree<UserProfile> = {
  PROFILE_FULLFILED(state, payload: []) {
    state.data = payload;
  },
  PROFILE_REJECTED(state, payload: string) {
    state.error = payload;
  },
  RESET_PROFILE(state) {
    state.data = [];
  },
};
