import { GetterTree } from "vuex";
import { RootState } from "../types";
import { INylasState } from "./types";

export const getters: GetterTree<INylasState, RootState> = {
  getAccessToken(state): string {
    return state.accessToken;
  },
  getIsCalendarLinked(state): boolean {
    return state.isCalendarLinked;
  },
  getIsTeamsLinked(state): boolean {
    return state.isTeamsLinked;
  },
  getProvider(state): string {
    return state.provider
  }
};
