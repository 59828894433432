
import { computed, defineComponent, onMounted, ref, Ref } from "vue";
import SwitchButton from "@/components/switchButton/index.vue";
import Checkbox from "@/components/elements/checkbox/index.vue";
import Loader from "@/components/loader/index.vue";
import { NotificationsAPI } from "@/services/notifications";
import { useStore } from "vuex";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Settings Job Notifications",
  components: {
    SwitchButton,
    Checkbox,
    Loader,
  },
  setup() {
    const store = useStore();
    const userId = computed(() => store.state.profile.data.id);
    const loading: Ref<boolean> = ref(false);
    const loadingSave: Ref<boolean> = ref(false);
    const toggles: Ref<Array<boolean>> = ref([]);
    const disabled: Ref<Array<boolean>> = ref([]);
    const jobs: Ref<any> = ref([]);

    function setAllNotifications(i: number) {
      const newVal = !toggles.value[i];
      for (const j in jobs.value[i].notifications) {
        if (!jobs.value[i].notifications[j].disabled) {
          jobs.value[i].notifications[j].value = newVal;
        }
      }
      toggles.value[i] = newVal;
    }

    function setToggles(i: number) {
      const jobkeys = [] as any;
      for (const j in jobs.value[i].notifications) {
        jobkeys.push(jobs.value[i].notifications[j].value);
      }
      toggles.value[i] = jobkeys.some((v: any) => v === true);
    }

    async function getJobs() {
      loading.value = true;
      const data = await NotificationsAPI.getJobNotifications();
      if (data) {
        jobs.value = data.job_members;
        toggles.value = [];
        disabled.value = [];
        for (let i = 0; i < jobs.value.length; i++) {
          setToggles(i);
        }
      } else {
        createToastAxelerate(
          "There was an error while trying to get the jobs",
          "Please try again in a few minutes",
          "danger"
        );
      }
      loading.value = false;
    }

    async function saveChanges(jobIndex: number) {
      loadingSave.value = true;
      const jobId = jobs.value[jobIndex].job.id;
      const body = {
        job_member: {
          notifications: {} as any,
        },
      };
      for (const j in jobs.value[jobIndex].notifications) {
        if (!jobs.value[jobIndex].notifications[j].disabled) {
          body.job_member.notifications[j] =
            jobs.value[jobIndex].notifications[j].value;
        } else {
          body.job_member.notifications[j] = false;
        }
      }
      const data = await NotificationsAPI.saveJobNotifications(
        jobId,
        userId.value,
        body
      );
      if (data) {
        jobs.value[jobIndex] = data.job_member;
        setToggles(jobIndex);
        createToastAxelerate(
          "Notifications preferences saved sueccessfully",
          "",
          "success"
        );
      } else {
        createToastAxelerate(
          "Error saving notification preferences",
          "Please try again in a few minutes",
          "danger"
        );
      }
      loadingSave.value = false;
    }

    onMounted(() => {
      getJobs();
    });

    return {
      loading,
      loadingSave,
      jobs,
      toggles,
      saveChanges,
      setToggles,
      setAllNotifications,
    };
  },
});
