
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from "vue";
const CustomSearch = defineComponent({
  name: "CustomSearch",
  emits: ["onSubmit", "update:modelValue"],
  inheritAttrs: false,
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    style: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: "search",
    },
  },
  model: {
    prop: "modelValue",
    event: "update",
  },
  setup(props, context) {
    const search = (e: any) => {
      context.emit("onSubmit", e.target.value);
    };
    return { search };
  },
});
export default CustomSearch;
