
import { computed, defineComponent, ref, Ref } from "vue";
import Checkbox from "@/components/elements/checkbox/index.vue";
import { AccountMembersAPI } from "@/services/accountMembers";
import { createToastAxelerate } from "@/utils/toast";
import { useStore } from "vuex";

export default defineComponent({
  name: "Invites List",
  components: {
    Checkbox,
  },
  setup() {
    const loading: Ref<boolean> = ref(false);

    const invitesList: Ref<Array<any>> = ref([
      { email: "", sourcing: false, workforce: false, recruiter: false },
    ]);
    const store = useStore();
    const isValid = ref(false);
    const hascompanyPlan = computed(
      () => store.state.profile.data?.company_plan_resources
    );
    const userProducts = computed(
      () => store.state.profile.data.company_products
    );
    const hasWorkfoceProduct = computed(() =>
      userProducts.value.some((item: any) => item.product.name === "workforce")
    );
    const remaining_collaborators = computed(
      () =>
        store.state.profile.data?.company_plan_resources
          ?.remaining_collaborators
    );
    function addNewLine() {
      invitesList.value.push({
        email: "",
        sourcing: false,
        workforce: false,
        recruiter: false,
      });
    }

    function removeLine(index: number) {
      invitesList.value.splice(index, 1);
    }

    function changeRole(
      index: number,
      sourcing: boolean,
      workforce: boolean,
      recruiter: boolean
    ) {
      if (sourcing || workforce || recruiter) {
        invitesList.value[index].sourcing = sourcing;
        invitesList.value[index].workforce = workforce;
        invitesList.value[index].recruiter = recruiter;
        isValid.value = true;
      } else {
        /* createToastAxelerate(
          "You must select at least one platform",
          "",
          "danger"
        ); */
        isValid.value = false;
      }
    }

    async function sendInvites() {
      loading.value = true;
      const body = {
        account_members: [] as Array<any>,
      };
      for (let i = 0; i < invitesList.value.length; i++) {
        const roles = [];
        if (invitesList.value[i].sourcing) {
          roles.push("sourcing_admin");
        }
        if (invitesList.value[i].recruiter) {
          roles.push("sourcing_recruiter");
        }
        if (invitesList.value[i].workforce) {
          roles.push("workforce_admin");
        }
        body.account_members.push({
          email: invitesList.value[i].email,
          roles: roles,
        });
      }
      let data = null;
      let errors = null;
      try {
        data = await AccountMembersAPI.sendInvite(body);
      } catch (err: any) {
        errors = err.response.data.errors;
        if (errors.length > 0) {
          errors = errors[0];
        } else {
          errors = "Plase try again";
        }
      }
      if (data) {
        createToastAxelerate("Invites sent succesfully", "", "success");
        window.location.reload();
      } else {
        createToastAxelerate("Error sending the invites", errors, "danger");
      }
      loading.value = false;
    }

    return {
      loading,
      invitesList,
      sendInvites,
      addNewLine,
      removeLine,
      changeRole,
      isValid,
      hascompanyPlan,
      remaining_collaborators,
      hasWorkfoceProduct,
    };
  },
});
