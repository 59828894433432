/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { UserProfile } from "./types";
import { userAPI } from "../../services/user/index";

export const actions: ActionTree<UserProfile, RootState> = {
  getProfile({ commit }) {
    return new Promise((resolve, reject) => {
      userAPI
        .getProfile()
        .then((data: any) => {
          commit("PROFILE_FULLFILED", data.data);
          resolve(data);
        })
        .catch((err) => {
          commit("PROFILE_REJECTED", err);
          reject(err);
        });
    });
  },
};
