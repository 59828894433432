
/* eslint-disable @typescript-eslint/no-explicit-any */
import { computed, defineComponent, onBeforeMount, onMounted, ref } from "vue";
import { BillingAPI } from "../../services/billing/index";
import Button from "../button/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import Skeleton from "../skeleton/index.vue";
import { useStore } from "vuex";

const PaymentMethods = defineComponent({
  name: "PaymentMethods",
  components: {
    Button,
    //Skeleton,
  },
  setup() {
    const isLoading = ref(false);
    const store = useStore();
    const isEditing = ref(false);
    const emailContacts = ref([]);
    const emailError = ref(false);
    const payment_contacts = ref([""]);
    const email = ref("");
    const isLoadingButton = ref(false);
    const companyId = computed(() => store.state.profile.data.company.id);
    payment_contacts.value = [];
    const auxliliarContacts = ref([]);
    const addEmail = () => {
      //eslint-disable-next-line
      let regex = /\S+@\S+\.\S+/;
      if (regex.test(email.value)) {
        emailError.value = false;
        payment_contacts.value.push(email.value);
        email.value = "";
      } else {
        emailError.value = true;
      }
    };

    onMounted(() => {
      getPaymentContacts();
    });

    const getPaymentContacts = () => {
      BillingAPI.getPaymentMethods()
        .then(async (res: any) => {
          payment_contacts.value = res.data.payment_contacts;
          auxliliarContacts.value = Object.assign([], payment_contacts.value);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const deleteContacts = (index: number) => {
      payment_contacts.value.splice(index, 1);
    };

    const saveChanges = () => {
      isLoadingButton.value = true;
      let params = {
        payment_contacts: payment_contacts.value,
      };
      BillingAPI.savePaymentContacts(companyId.value, params)
        .then((response) => {
          getPaymentContacts();
          createToastAxelerate(
            "Payment Contacts",
            "Your payment contacts has been saved",
            "success"
          );
          isLoadingButton.value = false;
          isEditing.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const onCancel = () => {
      payment_contacts.value = Object.assign([], auxliliarContacts.value);
      isEditing.value = false;
    };

    return {
      email,
      onCancel,
      addEmail,
      isLoading,
      isEditing,
      emailError,
      saveChanges,
      emailContacts,
      deleteContacts,
      isLoadingButton,
      payment_contacts,
    };
  },
});

export default PaymentMethods;
