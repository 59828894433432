import { AxiosInstance } from "axios";
import { client } from "../config";

class NotificationsAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = client;
  }

  async getNotificationSettings() {
    try {
      const { data } = await this.axiosInstance.get("/notification_settings");
      return data;
    } catch (err) {
      return null;
    }
  }
  async saveNotificationSettings(settings: any) {
    try {
      const { data } = await this.axiosInstance.put(
        "/notification_settings",
        settings
      );
      return data;
    } catch (err) {
      return null;
    }
  }
  async getJobNotifications() {
    try {
      const { data } = await this.axiosInstance.get("/member_jobs");
      return data;
    } catch (err) {
      return null;
    }
  }
  async saveJobNotifications(jobId: string, userId: string, body: any) {
    try {
      const { data } = await this.axiosInstance.put(
        `/jobs/${jobId}/job_members/${userId}`,
        body
      );
      return data;
    } catch (err) {
      return null;
    }
  }
  /*async stripeUpdateDefaultCard(cardId: string): Promise<unknown> {
    return this.axiosInstance.get(`/stripe/update_default_card/${cardId}`);
  }*/
}

export const NotificationsAPI = new NotificationsAPIService();
