/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionTree } from "vuex";

import { nylasAPI } from "@/services/nylas";
import { RootState } from "../types";
import { INylasState } from "./types";
import { NylasMutations } from "./mutations";

export const actions: ActionTree<INylasState, RootState> = {
  retrieveAccessToken({ commit }) {
    return new Promise((resolve, reject) => {
      nylasAPI
        .getAccessToken()
        .then((res: any) => {
          const accessToken = res.data.access_token;
          let isLinked = false;

          resolve(false);
          commit("SET_ACCESS_TOKEN", accessToken);

          if (accessToken) {
            isLinked = true;
          }
          commit("SET_IS_CALENDAR_LINKED", isLinked);
          commit(NylasMutations.SET_PROVIDER, res.provider);
        })
        .catch(() => {
          commit("SET_IS_CALENDAR_LINKED", false);
          reject(true);
        });
    });
  },
};
