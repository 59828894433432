/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const makeFilter = (filter: any): any => {
  if (!filter) {
    return "";
  }
  let params = "";
  const total = Object.keys(filter).length - 1;

  Object.keys(filter).forEach((key, index) => {
    if (filter[key] === undefined) {
      return;
    }

    params += `${key}=${filter[key]}`;
    if (index !== total) {
      params += "&";
    }
  });
  return params;
};

export const removeBlankProperties = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    const hasProperties = value && Object.keys(value).length > 0;
    if (!value) {
      delete obj[key];
    } else if (typeof value !== "string" && hasProperties) {
      removeBlankProperties(value);
    }
  });
  return obj;
};
