
import { defineComponent } from "vue";
import UserDetails from "../../components/userDetails/index.vue";
import SettingsCompanyDetails from "../../components/settingsCompanyDetails/index.vue";
import SettingsCompanySubscription from "../../components/settingsCompanySubscription/index.vue";
import SettingsChangePassword from "../../components/settingsChangePassword/index.vue";
import SettingsNylas from "@/components/settingsNylas/index.vue";

const UserSettings = defineComponent({
  name: "UserSettings",
  components: {
    UserDetails,
    SettingsCompanyDetails,
    SettingsCompanySubscription,
    SettingsChangePassword,
    SettingsNylas,
  },
});

export default UserSettings;
