
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  onMounted,
  computed,
  watchEffect,
  watch,
} from "vue";
import VueTableLite from "vue3-table-lite/ts";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { StripeTransactionsAPI } from "@/services/stripeTransactions";
import DateFormatter from "@/components/dateFormatter/index.vue";
import Paginator from "@/components/paginator/index.vue";
import "@/styles/pagination.css";
import { removeBlankProperties } from "@/utils/params";
import SearchInput from "../../components/searchInput/index.vue";

export default defineComponent({
  name: "AccountBillingTable",
  components: {
    VueTableLite,
    DateFormatter,
    Paginator,
    SearchInput,
  },
  props: {
    visible: Boolean,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const tableData: Ref<Array<any>> = ref([]);
    const page = ref(1);
    const pagination: Ref<any> = ref({});
    const openModal = ref(false);
    const filters = ref({});
    const inputValue = ref("");
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Service",
          field: "transaction_type",
          isKey: false,
          width: "20%",
        },
        {
          label: "Date",
          field: "created_at",
          isKey: true,
          sortable: true,
          width: "20%",
        },
        {
          label: "Payment date",
          field: "updated_at",
          isKey: false,
          sortable: true,
          width: "16%",
        },
        {
          label: "Status",
          field: "status",
          isKey: false,
          sortable: true,
          width: "14%",
        },
        {
          label: "Amount",
          field: "amount",
          sortable: true,
          width: "18%",
        },
        {
          label: "Invoice (PDF)",
          field: "invoice_url",
          isKey: true,
          width: "43%",
        },
      ],
      rows: [] as any,
      totalRecordCount: 0,
      sortable: {
        order: "total",
        sort: "asc",
      },
    });
    async function getTransactions() {
      table.isLoading = true;
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
      });
      const res = (await StripeTransactionsAPI.getStripeTransactions(
        page.value,
        _filters
      )) as any;

      if (res) {
        tableData.value = res.data.stripe_transactions;
        pagination.value = res.data?.meta?.pagination;

        setTable(tableData.value);
      }
      table.isLoading = false;
    }
    function setTable(rows: Array<any>) {
      table.rows = rows;
      table.totalRecordCount = rows.length;
    }

    function searchTitle(event: any) {
      const value = event.target.value.toLowerCase();
      const data = tableData.value.filter(function (row) {
        return row.transaction_type.toLowerCase().includes(value);
      });
      setTable(data);
    }

    function updateHandler(e: any) {
      page.value = e;
      getTransactions();
    }

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
      });
    });

    watch(inputValue, (newVal) => {
      if (newVal) {
        page.value = 1;
      }
    });

    onMounted(() => {
      getTransactions();
    });

    return {
      table,
      searchTitle,
      openModal,
      getTransactions,
      page,
      pagination,
      updateHandler,
      filters,
      inputValue,
    };
  },
});
