
import { defineComponent } from "vue";
import AccountBillingTable from "../../components/accountBillingTable/index.vue";
import AccountPaymentMethods from "../../components/paymentMethod/index.vue";
import NextBilling from "../../components/nextBilling/index.vue";
import PaymentContacts from "../../components/paymentContacts/index.vue";

const AccountBillingView = defineComponent({
  name: "AccountBillingView",
  components: {
    AccountBillingTable,
    AccountPaymentMethods,
    NextBilling,
    PaymentContacts,
  },
  setup() {
    return {};
  },
});
export default AccountBillingView;
