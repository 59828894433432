import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "flex flex-col md:flex-row lg:flex-row gap-4" }
const _hoisted_3 = { class: "lg:w-1/4 sm:w-full" }
const _hoisted_4 = { class: "bg-white shadow rounded-2xl p-4 lg:w-full sm:w-full" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex flex-row space-x-3 justify-bewteen" }
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = { class: "lg:w-2/3 sm:w-full" }
const _hoisted_9 = {
  key: 0,
  class: "h-full space-y-5"
}
const _hoisted_10 = {
  key: 1,
  class: "h-full space-y-5"
}
const _hoisted_11 = {
  key: 2,
  class: "h-full space-y-5"
}
const _hoisted_12 = {
  key: 3,
  class: "h-full space-y-5"
}
const _hoisted_13 = {
  key: 4,
  class: "h-full space-y-5"
}
const _hoisted_14 = {
  key: 5,
  class: "h-full space-y-5"
}
const _hoisted_15 = {
  key: 6,
  class: "h-full space-y-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_UserSettings = _resolveComponent("UserSettings")!
  const _component_AccountMembersView = _resolveComponent("AccountMembersView")!
  const _component_AccountBillingView = _resolveComponent("AccountBillingView")!
  const _component_NotificationSettings = _resolveComponent("NotificationSettings")!
  const _component_SettingsJobNotifications = _resolveComponent("SettingsJobNotifications")!
  const _component_BoardConfig = _resolveComponent("BoardConfig")!
  const _component_ServicesTable = _resolveComponent("ServicesTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
                return (_openBlock(), _createElementBlock("div", { key: index }, [
                  _createElementVNode("li", {
                    onClick: ($event: any) => (_ctx.clickTab(tab.key)),
                    class: _normalizeClass(["cursor-pointer py-3 px-5 rounded transition", _ctx.activeTab !== tab.key && 'opacity-50']),
                    style: _normalizeStyle(
                  _ctx.activeTab === tab.key ? { backgroundColor: '#F0FFFF' } : {}
                )
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(tab.value), 1)
                    ])
                  ], 14, _hoisted_5)
                ]))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_ctx.activeTab === 'settings')
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_UserSettings)
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'members')
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createVNode(_component_AccountMembersView)
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'billing')
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_AccountBillingView)
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'notifications')
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_NotificationSettings)
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'job-notifications')
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(_component_SettingsJobNotifications)
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'job-board')
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(_component_BoardConfig)
              ]))
            : _createCommentVNode("", true),
          (_ctx.activeTab === 'services')
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createVNode(_component_ServicesTable)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}