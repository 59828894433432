<template>
  <input
    @input="check()"
    type="checkbox"
    :checked="checked"
    :id="fieldId"
    class="mr-2 hidden"
    v-bind="$attrs"
  />
  <label
    :for="fieldId"
    class="flex flex-row items-center text-sm cursor-pointer select-none overflow-auto"
  >
    <i
      class="fa mr-2"
      :class="{
        [`${size} fa-check-circle text-primary`]: checked,
        [`${size} fa-circle text-gray-300`]: !checked,
      }"
    ></i>
    <slot>{{ label }}</slot>
  </label>
</template>

<script>
import { computed, defineComponent } from "vue";

const CheckBoxGroup = defineComponent({
  name: "CheckBoxGroup",
  emits: ["update:names"],
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    size: {
      type: String,
      default: "fa-lg",
    },
    fieldId: {
      type: [String, Number],
      required: true,
    },
    names: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
  },
  setup(props, context) {
    const checked = computed(() => props.names.includes(props.value));
    const check = () => {
      let updatedNames = [...props.names];
      if (checked.value) {
        updatedNames.splice(updatedNames.indexOf(props.value), 1);
      } else {
        updatedNames.push(props.value);
      }
      context.emit("update:names", updatedNames);
    };

    return {
      check,
      checked,
    };
  },
});

export default CheckBoxGroup;
</script>
