
import { defineComponent, ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import VueTableLite from "vue3-table-lite/ts";
import AccontMembersTable from "../../components/accountMembersTable/index.vue";
import { StripeTransactionsAPI } from "@/services/stripeTransactions";
import { createToastAxelerate } from "@/utils/toast";

const ServicesTable = defineComponent({
  name: "ServicesTable",
  components: { VueTableLite },
  setup() {
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Service",
          field: "nickname",
          isKey: false,
          width: "30%",
        },
        {
          label: "Start Date",
          field: "start_date",
          isKey: true,
          sortable: true,
          width: "20%",
        },
        {
          label: "Status",
          field: "subscription_status",
          isKey: false,
          sortable: true,
          width: "16%",
        },

        {
          label: "Quantity",
          field: "quantity",
          sortable: true,
          width: "30%",
        },
        {
          label: "Amount",
          field: "amount",
          isKey: false,
          sortable: true,
          width: "30%",
        },
      ],
      rows: [] as any,
      totalRecordCount: 0,
      sortable: {
        order: "total",
        sort: "asc",
      },
    });

    onMounted(() => {
      table.isLoading = true;
      StripeTransactionsAPI.getStripeSuscriptions()
        .then((response: any) => {
          setTable(response.data.company_subscriptions);
          table.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          table.isLoading = false;
        });
    });

    function setTable(rows: Array<any>) {
      table.rows = rows;
      table.totalRecordCount = rows.length;
    }

    return { table };
  },
});
export default ServicesTable;
