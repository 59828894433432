
import { defineComponent, Ref, ref } from "vue";
import TextField from "@/components/elements/textField/index.vue";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";
import Spinner from "@/components/elements/spinner/index.vue";
import { ForgotPasswordAPI } from "../../services/forgotPassword/index";
import { createToastAxelerate } from "../../utils/toast";

export default defineComponent({
  name: "Forgot Password",
  components: {
    TextField,
    Spinner,
  },
  setup() {
    const schema = yup.object({
      email: yup.string().required().email().label("Email"),
    });

    const { handleSubmit } = useForm({
      validationSchema: schema as any,
    });

    const { value: email } = useField("email");

    function invalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }
    const onSubmit = handleSubmit((values) => {
      sendEmail(values);
    }, invalidSubmit);

    //Variables
    const emailSent = ref(false);
    const fieldErrors: Ref<Array<string>> = ref([]);
    const loading = ref(false);

    //Functions
    async function sendEmail(email: any) {
      loading.value = true;
      ForgotPasswordAPI.recoverPassword(email)
        .then((response: any) => {
          emailSent.value = true;
          loading.value = false;
        })
        .catch((error) => {
          createToastAxelerate(
            "Set Password",
            error.response.data.errors[0],
            "danger"
          );
        });
    }

    return {
      emailSent,
      email,
      schema,
      fieldErrors,
      onSubmit,
      sendEmail,
      loading,
    };
  },
});
