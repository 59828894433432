import { AxiosInstance } from "axios";
import { client } from "../config";

export default class CountriesAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = client;
  }

  async index(): Promise<unknown> {
    return this.axiosInstance.get(`/countries`);
  }
}
export const CountriesAPI = new CountriesAPIService();
