import { MutationTree } from "vuex";
import { INylasState } from "./types";

export enum NylasMutations {
  SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN",
  SET_IS_CALENDAR_LINKED = "SET_IS_CALENDAR_LINKED",
  SET_IS_TEAMS_LINKED = "SET_IS_TEAMS_LINKED",
  SET_PROVIDER = "SET_PROVIDER"
}

export const mutations: MutationTree<INylasState> = {
  [NylasMutations.SET_ACCESS_TOKEN](state, payload: string) {
    state.accessToken = payload;
  },
  [NylasMutations.SET_IS_CALENDAR_LINKED](state, payload: boolean) {
    state.isCalendarLinked = payload;
  },
  [NylasMutations.SET_IS_TEAMS_LINKED](state, payload: boolean) {
    state.isTeamsLinked = payload;
  },
  [NylasMutations.SET_PROVIDER](state, provider: string) {
    state.provider = provider
  }
};
