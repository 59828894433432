
import router from "@/router";
import { computed, defineComponent, onBeforeMount, ref } from "vue";

const PoweredByFooter = defineComponent({
  name: "Powered By Footer",
  emits: [],
  setup(props, context) {
    const branding = {
      backgroundColor: 'bg-primary'
    }
    const currentRoute = computed(() => router.currentRoute.value.name);
    const host = window.location.host,
          domain = host.split(':')[0],
          subdomainComponents = domain.split('.'),
          subdomain = subdomainComponents[0]
    let isWhitelabelJse = ref(false)

    onBeforeMount(() => {
      if (['mtydigitalhub', 'mtydigitalhub-test'].includes(subdomain)) {
        isWhitelabelJse.value = true
        let hubBranding = {
          backgroundColor: 'branding-background'
        }

        Object.assign(branding, hubBranding)
      }
    })

    return { branding, currentRoute, isWhitelabelJse };
  },
});

export default PoweredByFooter;
