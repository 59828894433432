
import { defineComponent, ref, onBeforeMount } from "vue";
import { useRoute } from "vue-router";
import Button from "../../components/button/index.vue";
import { userAPI } from "../../services/user";
import { createToastAxelerate } from "../../utils/toast";
import Loader from "../../components/loader/index.vue";

const SignUpSuccess = defineComponent({
  name: "SignUpSuccess",
  components: { Button, Loader },
  props: {
    email: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const route = useRoute();
    const sentEmail = ref("");
    const isLoading = ref(false);
    const token = ref("");

    onBeforeMount(() => {
      sentEmail.value = props.email;
      token.value = props.token;
    });

    const resendEmailConfirmation = () => {
      isLoading.value = true;
      setTimeout(() => {
        userAPI
          .resendEmailConfirmation(token.value)
          .then(async (res: any) => {
            token.value = res.data.token_id;
            createToastAxelerate(
              "Email confirmation",
              "Email has been sent",
              "success"
            );
          })
          .catch(() => {
            createToastAxelerate(
              "Email confirmation",
              "An error has occurred. ",
              "danger"
            );
          });
        isLoading.value = false;
      }, 2000);
    };

    return {
      route,
      sentEmail,
      resendEmailConfirmation,
      isLoading,
    };
  },
});

export default SignUpSuccess;
