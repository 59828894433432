
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import ErrorState from "@/components/elements/errorState/index.vue";
import TextField from "@/components/elements/textField/index.vue";
import { CompleteAccountAPI } from "../../services/completeAccount/index";
import { createToastAxelerate } from "../../utils/toast";
import * as yup from "yup";
import Spinner from "@/components/elements/spinner/index.vue";
import { useForm } from "vee-validate";
import { optionDepartament } from "../../constants/jobs";

export default defineComponent({
  name: "Complete Account",
  props: {
    token: String,
  },
  components: {
    ErrorState,
    TextField,
    Spinner,
  },
  setup() {
    const isOwner = ref(true);
    const schema = yup.object({
      first_name: yup.string().required().max(50).min(3).label("First name"),
      last_name: yup.string().required().max(50).min(3).label("Last name"),
      email: yup.string().required().email(),
      company_name: yup.string().required().label("Company name"),
      password: yup
        .string()
        .required()
        .min(8)
        .matches(
          /[0-9A-Za-z]*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?][0-9a-zA-Z]*$/,
          "Must contain at least one uppercase, special character and number"
        ),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    });
    const { handleSubmit, errors, setValues } = useForm({
      validationSchema: schema,
    });
    const onSubmit = handleSubmit((values) => {
      const user = {
        user: {
          ...values,
          password_confirmation: values.password_confirmation,
          token: route.query.token,
        },
      };
      updateData(user);
    });

    //Variables
    const loading = ref(false);
    const error = ref(false);
    const step = ref(1);
    const router = useRouter();
    const route = useRoute();
    const userId = ref("");

    //Functions

    function updateData(user: any) {
      loading.value = true;
      CompleteAccountAPI.updateAccount(userId.value, user)
        .then(() => {
          createToastAxelerate(
            "Account completed",
            "The user has been activated.",
            "success"
          );
          loading.value = false;
          router.push("/login");
        })
        .catch(() => {
          loading.value = false;
        });
    }

    //Hooks
    onBeforeMount(() => {
      CompleteAccountAPI.getCompleteAccount(route.query.token)
        .then((res: any) => {
          const { data } = res;
          userId.value = data.id;
          isOwner.value = data?.is_owner ? false : true;
          setValues({
            first_name: data.first_name ? data.first_name : "",
            last_name: data.last_name ? data.last_name : "",
            email: data.email,
            company_name: data.company_name,
          });
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          loading.value = false;
          createToastAxelerate(
            "Complete Account",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
    });
    const fields = [
      {
        name: "first_name",
        label: "First name",
        type: "text",
        disabled: false,
      },
      {
        name: "last_name",
        label: "Last name",
        type: "text",
        disabled: false,
      },
      {
        name: "email",
        label: "Email",
        type: "email",
        disabled: true,
      },
      {
        name: "company_name",
        label: "Company name",
        type: "text",
        disabled: isOwner.value,
      },
    ];
    const passwords = [
      {
        name: "password",
        label: "Password",
        type: "password",
        disabled: false,
      },
      {
        name: "password_confirmation",
        label: "Password confirmation",
        type: "password",
        disabled: false,
      },
    ];
    return {
      loading,
      error,
      step,
      onSubmit,
      schema,
      errors,
      userId,
      updateData,
      isOwner,
      fields,
      passwords,
      optionDepartament,
    };
  },
});
