
import { computed, defineComponent, onMounted, ref } from "vue";
import Navbar from "@/components/navBarJobBoard/index.vue";
import Tooltip from "../../components/toolTip/index.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { JobBoardService } from "@/services/jobBoard";

export default defineComponent({
  components: {
    Tooltip,
    Navbar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const profile = computed(() => store.state.profile?.data?.candidate);
    const industries = ref([]);

    const edit = (tab: string) => {
      router.push(`/edit-candidate/${tab}`);
    };

    const getFileName = (filename: string) => {
      if (!filename) return "undefined";
      let auxFileName = filename.substring(filename.lastIndexOf("/") + 1);
      return auxFileName.substr(0, auxFileName.lastIndexOf("."));
    };
    const getFileExtension = (filename: string) => {
      return filename ? filename.split(".").pop() : "undefined";
    };

    const getPreferredWorks = (data: any) => {
      const workTypes = [];
      for (const [key, value] of Object.entries(data)) {
        if (value === true) {
          workTypes.push(key);
        }
      }
      return workTypes;
    };
    onMounted(async () => {
      await store.dispatch("getProfile");
      getIndustries();
    });

    async function getIndustries() {
      await JobBoardService.getCompanyIndustries().then((res: any) => {
        industries.value = res.data;
      });
    }

    const getIndustryName = (id: string) => {
      let name = "";
      industries.value.forEach((element: any) => {
        if (element.id === id) {
          name = element.name;
        }
      });
      return name;
    };

    return {
      profile,
      router,
      industries,
      edit,
      getFileName,
      getFileExtension,
      getPreferredWorks,
      getIndustryName,
    };
  },
});
