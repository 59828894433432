<template>
  <button
    ref="btnRef"
    v-on:mouseenter="toggleTooltip()"
    v-on:mouseleave="toggleTooltip()"
    class="focus:outline-none ease-linear transition-all duration-150"
    type="button"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5 text-gray-400"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fill-rule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
        clip-rule="evenodd"
      />
    </svg>
  </button>
  <div
    ref="tooltipRef"
    :class="[
      {
        hidden: !tooltipShow,
        block: tooltipShow,
      },
      bgColor,
    ]"
    class="w-60 z-50 text-sm text-justify no-underline break-words rounded-2xl shadow"
  >
    <slot />
    <div v-if="type === 'list'" class="px-3 py-2">
      <ul class="text-xs sm:text-sm">
        <li class="list-item" v-for="item in liItems" :key="item">
          {{ item }}
        </li>
      </ul>
    </div>
    <div v-if="type === 'default'" class="px-3 py-2 bg-white rounded-xl">
      <span class="block text-left text-sm">{{ message }}</span>
    </div>
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { defineComponent, ref } from "vue";

const Tooltip = defineComponent({
  name: "Tooltip",
  props: {
    bgColor: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "default",
    },
    liItems: {
      type: Array,
      default: () => [],
    },
    message: {
      type: String,
      default: "",
    },
  },
  setup() {
    const tooltipShow = ref(false);
    const btnRef = ref(null);
    const tooltipRef = ref(null);
    const toggleTooltip = () => {
      if (tooltipShow.value) {
        tooltipShow.value = false;
      } else {
        tooltipShow.value = true;
        createPopper(btnRef.value, tooltipRef.value, {
          placement: "top",
        });
      }
    };
    return { tooltipShow, toggleTooltip, btnRef, tooltipRef };
  },
});

export default Tooltip;
</script>
