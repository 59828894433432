import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f0205a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-wrap items-center mb-4 pt-4 text-sm" }
const _hoisted_2 = { class: "w-full" }
const _hoisted_3 = { class: "px-2 py-1 bg-gray-100 rounded-2xl" }
const _hoisted_4 = { class: "flex flex-row justify-center" }
const _hoisted_5 = { class: "px-2 py-1 bg-gray-100 rounded-2xl" }
const _hoisted_6 = { class: "flex flex-row justify-center text-center" }
const _hoisted_7 = {
  key: 0,
  class: "px-2 py-1 bg-gray-100 rounded-2xl"
}
const _hoisted_8 = {
  key: 1,
  class: "px-2 py-1 bg-gray-100 rounded-2xl w-24"
}
const _hoisted_9 = { class: "flex flex-row justify-center text-center" }
const _hoisted_10 = {
  key: 0,
  class: "px-2 py-1 bg-orange-300 rounded-2xl w-24"
}
const _hoisted_11 = {
  key: 1,
  class: "px-2 py-1 bg-green-300 rounded-2xl w-24"
}
const _hoisted_12 = { class: "flex flex-row justify-center" }
const _hoisted_13 = { class: "px-2 py-1 bg-black rounded-2xl text-white uppercase" }
const _hoisted_14 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_DateFormatter = _resolveComponent("DateFormatter")!
  const _component_VueTableLite = _resolveComponent("VueTableLite")!
  const _component_Paginator = _resolveComponent("Paginator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SearchInput, {
        modelValue: _ctx.inputValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        class: "bg-white",
        placeholder: "Search for a transaction...",
        onOnSubmit: _ctx.getTransactions
      }, null, 8, ["modelValue", "onOnSubmit"])
    ]),
    _createVNode(_component_VueTableLite, {
      "is-loading": _ctx.table.isLoading,
      columns: _ctx.table.columns,
      rows: _ctx.table.rows,
      total: _ctx.table.totalRecordCount,
      sortable: _ctx.table.sortable,
      "is-slot-mode": true,
      "is-static-mode": true,
      "is-hide-paging": true
    }, {
      transaction_type: _withCtx((data) => [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(data.value.transaction_type), 1)
        ])
      ]),
      created_at: _withCtx((data) => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_DateFormatter, {
              date: data.value.created_at
            }, null, 8, ["date"])
          ])
        ])
      ]),
      updated_at: _withCtx((data) => [
        _createElementVNode("div", _hoisted_6, [
          (data.value.status === 'complete')
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                _createVNode(_component_DateFormatter, {
                  date: data.value.created_at
                }, null, 8, ["date"])
              ]))
            : (_openBlock(), _createElementBlock("span", _hoisted_8, "-"))
        ])
      ]),
      status: _withCtx((data) => [
        _createElementVNode("div", _hoisted_9, [
          (data.value.status === 'pending')
            ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Pending"))
            : _createCommentVNode("", true),
          (data.value.status === 'complete')
            ? (_openBlock(), _createElementBlock("span", _hoisted_11, "Paid"))
            : _createCommentVNode("", true)
        ])
      ]),
      amount: _withCtx((data) => [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("span", _hoisted_13, " $" + _toDisplayString((data.value.amount / 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })) + " " + _toDisplayString(data.value.currency), 1)
        ])
      ]),
      invoice_url: _withCtx((data) => [
        _createElementVNode("a", {
          href: data.value.invoice_url,
          target: "_blank",
          class: _normalizeClass(
            data.value.invoice_url === null ? 'btn-text-disabled' : 'btn-text'
          )
        }, "View invoice", 10, _hoisted_14)
      ]),
      _: 1
    }, 8, ["is-loading", "columns", "rows", "total", "sortable"]),
    _createVNode(_component_Paginator, {
      modelValue: _ctx.page,
      page: _ctx.page,
      pages: _ctx.pagination?.total_pages,
      "range-size": 1,
      "onUpdate:modelValue": _ctx.updateHandler,
      total: _ctx.pagination?.total_count,
      from: _ctx.pagination?.from,
      to: _ctx.pagination?.to,
      class: "mt-8"
    }, null, 8, ["modelValue", "page", "pages", "onUpdate:modelValue", "total", "from", "to"])
  ]))
}