
import { defineComponent } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useState } from "../../composables/useState";
import Tooltip from "../../components/toolTip/index.vue";
import { onMounted, ref } from "vue";
import { JobBoardService } from "@/services/jobBoard";

const ApplyJobFormSummary = defineComponent({
  name: "ApplyJobFormSummary",
  emits: ["jobApplied", "isSavingJob", "changeStep"],
  components: {
    Tooltip,
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    job: {
      type: Object,
      required: true,
    },
    skills: {
      type: Array,
      required: true,
    },
    candidate_location: {
      type: String,
      default: "",
    },
    workType: {
      type: Array,
      required: true,
    },
    fileResume: {
      required: false,
    },
    fileOther: {
      required: false,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const industries = ref([]);

    const edit = (step: number) => {
      context.emit("changeStep", step);
    };

    const getFileName = (file: any) => {
      if (!file) return "undefined";
      if (file.name) {
        return file.name.substr(0, file.name.lastIndexOf("."));
      } else {
        let auxFileName = file.substring(file.lastIndexOf("/") + 1);
        return auxFileName.substr(0, auxFileName.lastIndexOf("."));
      }
    };

    const getFileExtension = (file: any) => {
      if (!file) return "undefined";
      if (file.name) {
        return file.name.split(".").pop();
      } else {
        let auxFileName = file.substring(file.lastIndexOf("/") + 1);
        return auxFileName.split(".").pop();
      }
    };

    const getPreferredWorks = (data: any) => {
      if (!data) return null;
      const workTypes = [];
      for (const [key, value] of Object.entries(data)) {
        if (value === true) {
          workTypes.push(key);
        }
      }
      return workTypes;
    };

    onMounted(() => {
      getIndustries();
    });

    async function getIndustries() {
      await JobBoardService.getCompanyIndustries().then((res: any) => {
        industries.value = res.data;
      });
    }

    const getIndustryName = (id: string) => {
      let name = "";
      industries.value.forEach((element: any) => {
        if (element.id === id) {
          name = element.name;
        }
      });
      return name;
    };

    return {
      edit,
      getFileName,
      getFileExtension,
      getIndustryName,
      getPreferredWorks,
      isLoadingButton,
      router,
      setIsLoadingButton,
    };
  },
});

export default ApplyJobFormSummary;
