
import { defineComponent, onMounted, ref } from "vue";
import Button from "../../components/button/index.vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "404 Error",
  components: {
    Button,
  },
  setup() {
    const data = ref();
    const router = useRouter();

    return {
      data,
      router,
    };
  },
});
