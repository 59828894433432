
import { ref, defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { useState } from "../../composables/useState";

const PasswordField = defineComponent({
  name: "PasswordField",
  inheritAttrs: false,
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  model: {
    prop: "value",
    event: "update",
  },
  setup() {
    const [viewPassword, setViewPassword] = useState(false);
    return {
      ref,
      viewPassword,
      setViewPassword,
    };
  },
});
export default PasswordField;
