
import { defineComponent } from "vue";
import { Field, ErrorMessage } from "vee-validate";

const TextField = defineComponent({
  name: "TextField",
  inheritAttrs: false,
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    isError: {
      default: false,
      type: Boolean,
    },
    required: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    typeInput: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    board: {
      tyep: Boolean,
      default: false,
    },
  },
});
export default TextField;
