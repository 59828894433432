<template>
  <vue-recaptcha
    theme="light"
    size="normal"
    :tabindex="0"
    @widgetId="recaptchaWidget = $event"
    @verify="callbackVerify($event)"
    @expired="callbackExpired()"
    @fail="callbackFail()"
  />

  <!-- <button @click="actionReset()">reset!</button> -->
</template>

<script>
import { ref, defineComponent } from "vue";
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";

export default defineComponent({
  name: "reCaptcha",
  emits: ["verify", "error"],
  components: { VueRecaptcha },
  setup(props, { emit }) {
    // Reset Recaptcha
    const { resetRecaptcha } = useRecaptcha();
    const recaptchaWidget = ref(null);

    const callbackVerify = (response) => {
      emit("verify", response);
    };

    const callbackExpired = () => {
      console.log("expired!");
    };

    const callbackFail = () => {
      emit("error");
    };

    const actionReset = () => {
      resetRecaptcha(recaptchaWidget.value);
    };

    return {
      recaptchaWidget,
      callbackExpired,
      callbackVerify,
      callbackFail,
      actionReset,
    };
  },
});
</script>
