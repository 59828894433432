
import { computed, defineComponent, onMounted, ref, Ref } from "vue";
import SwitchButton from "@/components/switchButton/index.vue";
import { NotificationsAPI } from "@/services/notifications";
import Checkbox from "@/components/elements/checkbox/index.vue";
import { createToastAxelerate } from "@/utils/toast";

export default defineComponent({
  name: "Notification Settings",
  components: {
    SwitchButton,
    Checkbox,
  },
  setup() {
    const loading: Ref<boolean> = ref(false);
    const frequencies: Ref<Array<string>> = ref(["immediate", "daily", "weekly"]);
    const frequency: Ref<string> = ref("");
    const sourcing: Ref<any> = ref({});
    const workforce: Ref<any> = ref({});
    const allNotifications: Ref<boolean> = computed(
      () => allSourcing.value && allWorkforce.value
    );
    const allSourcing: Ref<boolean> = computed(() =>
      Object.values(sourcing.value).every((value) => value === true)
    );
    const allWorkforce: Ref<boolean> = computed(() =>
      Object.values(workforce.value).every((value) => value === true)
    );

    function changeSourcing(newVal: boolean) {
      Object.keys(sourcing.value).forEach((v) => {
        sourcing.value[v] = newVal;
      });
    }

    function changeWorkforce(newVal: boolean) {
      Object.keys(workforce.value).forEach((v) => {
        workforce.value[v] = newVal;
      });
    }

    function changeAll(newVal: boolean) {
      changeSourcing(newVal);
      changeWorkforce(newVal);
    }

    async function getNotificationSettings() {
      const data = await NotificationsAPI.getNotificationSettings();
      if (data) {
        if (data.notification_settings.sourcing_notifications) {
          sourcing.value = data.notification_settings.sourcing_notifications;
        }
        if (data.notification_settings.workforce_notifications) {
          workforce.value = data.notification_settings.workforce_notifications;
        }
        frequency.value = data.notification_settings.notification_interval;
      }
    }

    async function saveNotificationSettings() {
      loading.value = true;
      const settings = {
        notification_settings: {
          notification_interval: frequency.value,
          sourcing_notifications: sourcing.value,
          workforce_notifications: workforce.value,
        },
      };
      const data = await NotificationsAPI.saveNotificationSettings(settings);
      if (data) {
        createToastAxelerate("Settings saved successfully", "", "success");
        getNotificationSettings();
      } else {
        createToastAxelerate("Something went wrong", "Please try again", "danger");
      }
      loading.value = false;
    }

    onMounted(() => {
      getNotificationSettings();
    });

    return {
      loading,
      frequency,
      frequencies,
      sourcing,
      workforce,
      allNotifications,
      allSourcing,
      allWorkforce,
      saveNotificationSettings,
      changeSourcing,
      changeWorkforce,
      changeAll,
    };
  },
  methods: {
    onChange(e: any) {
      this.frequency = e.target.value;
    },
  },
});
