import { AxiosInstance } from "axios";
import { client } from "../config";

export default class ForgotPasswordAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = client;
  }

  async recoverPassword(email: any): Promise<unknown> {
    return this.axiosInstance.post("/recover_password", email);
  }

  async resetPassword(data: any): Promise<unknown> {
    return this.axiosInstance.put("/set_password", data);
  }

  async changePassword(data: any): Promise<unknown> {
    return this.axiosInstance.put("/users/change_password", data);
  }
}
export const ForgotPasswordAPI = new ForgotPasswordAPIService();
