
import { useState } from "@/composables/useState";
import { removeBlankProperties } from "@/utils/params";
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watchEffect,
} from "vue";
import { useRouter } from "vue-router";
import { JobBoardService } from "../../services/jobBoard/index";
import Paginator from "../../components/paginator/index.vue";
import Loader from "@/components/loader/index.vue";
import CompanyCard from "../../components/companyCard/index.vue";
import SkeletonCard from "../../components/skeletonCard/index.vue";
import Navbar from "@/components/navBarJobBoard/index.vue";
import CustomSearch from "@/components/customSearch/index.vue";
import PoweredByFooter from "@/components/poweredByFooter/index.vue";

export default defineComponent({
  name: "Companies",
  components: {
    Paginator,
    Loader,
    CompanyCard,
    SkeletonCard,
    Navbar,
    CustomSearch,
    PoweredByFooter,
  },
  setup() {
    const router = useRouter();
    const companies = ref([]);
    const isLoadingPage = ref(true);
    const isLoadingCompany = ref(true);
    const [page, setPage] = useState(1);
    const filters = ref({});
    const inputJobValue = ref("");
    const inputLocationValue = ref("");
    const pagination = ref({});
    const branding = {
      accentColor: "",
    };
    const host = window.location.host,
      domain = host.split(":")[0],
      subdomainComponents = domain.split("."),
      subdomain = subdomainComponents[0];

    const getCompanies = () => {
      isLoadingCompany.value = true;
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
      });
      JobBoardService.getCompanies(page.value, _filters)
        .then((response: any) => {
          companies.value = response.data.companies;
          pagination.value = response.data.meta.pagination;
          isLoadingCompany.value = false;
          isLoadingPage.value = false;
        })
        .catch((error) => {
          isLoadingPage.value = false;
          isLoadingCompany.value = false;
        });
    };

    onBeforeMount(() => {
      if (["mtydigitalhub", "mtydigitalhub-test"].includes(subdomain)) {
        let hubBranding = {
          accentColor: "text-color",
        };

        Object.assign(branding, hubBranding);
      }
    });

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputJobValue.value
          ? inputJobValue.value
          : inputLocationValue.value,
      });
    });

    const updateHandler = (e: any) => {
      setPage(e);
      getCompanies();
    };

    const searchByCompanies = (event: any) => {
      inputJobValue.value = event;
      getCompanies();
    };

    const searchByLocation = (event: any) => {
      inputLocationValue.value = event;
      getCompanies();
    };

    onMounted(() => {
      getCompanies();
    });

    return {
      companies,
      router,
      isLoadingPage,
      page,
      setPage,
      filters,
      inputJobValue,
      inputLocationValue,
      pagination,
      updateHandler,
      isLoadingCompany,
      searchByCompanies,
      searchByLocation,
      branding,
    };
  },
});
