import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid grid-auto-fit gap-4" }
const _hoisted_2 = { class: "grid-item bg-white shadow rounded-2xl p-8" }
const _hoisted_3 = { class: "grid-item bg-white shadow rounded-2xl p-8" }
const _hoisted_4 = { class: "grid grid-auto-fit gap-4" }
const _hoisted_5 = { class: "grid-item bg-white shadow rounded-2xl p-8" }
const _hoisted_6 = { class: "grid-item" }
const _hoisted_7 = { class: "mb-4 bg-white shadow rounded-2xl p-8" }
const _hoisted_8 = { class: "mb-4 bg-white shadow rounded-2xl p-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserDetails = _resolveComponent("UserDetails")!
  const _component_SettingsCompanySubscription = _resolveComponent("SettingsCompanySubscription")!
  const _component_SettingsChangePassword = _resolveComponent("SettingsChangePassword")!
  const _component_SettingsCompanyDetails = _resolveComponent("SettingsCompanyDetails")!
  const _component_SettingsNylas = _resolveComponent("SettingsNylas")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_UserDetails)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SettingsCompanySubscription)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SettingsChangePassword)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_SettingsCompanyDetails)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_SettingsNylas)
        ])
      ])
    ])
  ], 64))
}