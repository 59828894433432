import { MutationTree } from "vuex";
import { UserState } from "./types";

export enum UserMutations {
  SET_USEREMAIL = "SET_USEREMAIL",
  SET_USERID = "SET_USERID",
  SET_USERFIRSTNAME = "SET_USERFIRSTNAME",
  SET_USERLASTNAME = "SET_USERLASTNAME",
  SET_USERROLE = "SET_USERROLE",
  SET_COMPANYID = "SET_COMPANYID",
  SET_COMPANYNAME = "SET_COMPANYNAME",
  SET_PRODUCTS = "SET_PRODUCTS",
  SET_PAYMENTS = "SET_PAYMENTS",
  SET_ONBOARDINGEMAIL = "SET_ONBOARDINGEMAIL",
  SET_ONBOARDINGFIRSTNAME = "SET_ONBOARDINGFIRSTNAME",
  SET_ONBOARDINGLASTNAME = "SET_ONBOARDINGLASTNAME",
  SET_CANDIDATEID = "SET_CANDIDATEID",
  SET_PREVIOUSURL = "SET_PREVIOUSURL",
  SET_GOTOONBOARDING = "SET_GOTOONBOARDING",
}

export const mutations: MutationTree<UserState> = {
  [UserMutations.SET_USEREMAIL](state, payload: string) {
    state.email = payload;
  },
  [UserMutations.SET_USERID](state, payload: string) {
    state.user_id = payload;
  },
  [UserMutations.SET_USERFIRSTNAME](state, payload: string) {
    state.first_name = payload;
  },
  [UserMutations.SET_USERLASTNAME](state, payload: string) {
    state.last_name = payload;
  },
  [UserMutations.SET_USERROLE](state, payload: string) {
    state.role = payload;
  },
  [UserMutations.SET_COMPANYID](state, payload: string) {
    state.company_id = payload;
  },
  [UserMutations.SET_COMPANYNAME](state, payload: string) {
    state.company_name = payload;
  },
  [UserMutations.SET_PRODUCTS](state, payload: Array<any>) {
    state.products = payload;
  },
  [UserMutations.SET_PAYMENTS](state, payload: Array<string>) {
    state.payments = payload;
  },
  [UserMutations.SET_ONBOARDINGEMAIL](state, payload: string) {
    state.onboardingEmail = payload;
  },
  [UserMutations.SET_ONBOARDINGFIRSTNAME](state, payload: string) {
    state.onboardingFirstName = payload;
  },
  [UserMutations.SET_PREVIOUSURL](state, payload: string) {
    state.previousUrl = payload;
  },
  [UserMutations.SET_ONBOARDINGLASTNAME](state, payload: string) {
    state.onboardingLastName = payload;
  },
  [UserMutations.SET_CANDIDATEID](state, payload: string) {
    state.candidateId = payload;
  },
  [UserMutations.SET_GOTOONBOARDING](state, payload: boolean) {
    state.goToOnboarding = payload;
  },
};
