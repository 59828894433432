import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/app.css";
import "./styles/helper.css";
import VueClickAway from "vue3-click-away";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import { globalCookiesConfig } from "vue3-cookies";
import VueRecaptcha from "vue3-recaptcha-v2";

globalCookiesConfig({
  expireTimes: "7d",
  //domain: "axelerate.io",
  //secure: true,
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueClickAway)
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyD0qCTGi6UJDmvJhUPgoYAccFUNguHJZiw",
      libraries: "places",
    },
  })
  .use(VueRecaptcha, {
    siteKey: process.env.VUE_APP_SITE_KEY,
    alterDomain: false,
  })
  .mount("#app");
