import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { auth } from "./auth";
import { user } from "./user";
import { profile } from "./profile";
import { nylas } from "./nylas";
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
});

const store: StoreOptions<RootState> = {
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    user,
    profile,
    nylas,
  },
  plugins: [vuexLocal.plugin],
};

export default new Vuex.Store<RootState>(store);
