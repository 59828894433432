
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  onMounted,
  computed,
  watchEffect,
  watch,
} from "vue";
import VueTableLite from "vue3-table-lite/ts";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import ReusableModal from "../../components/reusableModal/index.vue";
import InviteMember from "../../components/inviteAccountMember/index.vue";
import { AccountMembersAPI } from "@/services/accountMembers";
import { createToastAxelerate } from "../../utils/toast";
import Paginator from "@/components/paginator/index.vue";
import "@/styles/pagination.css";
import { removeBlankProperties } from "@/utils/params";
import SearchInput from "../../components/searchInput/index.vue";

export default defineComponent({
  name: "Collaborators Table",
  components: {
    VueTableLite,
    ReusableModal,
    InviteMember,
    Paginator,
    SearchInput,
  },
  props: {
    visible: Boolean,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const tableData: Ref<Array<any>> = ref([]);
    const openModal = ref(false);
    const client = ref({});
    const openDropdown = ref("");
    const currentDropdown = ref("");
    const page = ref(1);
    const filters = ref({});
    const inputValue = ref("");
    const showModalUpgrade = ref(false);
    const hascompanyPlan = computed(
      () => store.state.profile.data?.company_plan_resources
    );
    const canAddMembers = computed(
      () =>
        store.state.profile.data?.company_plan_resources?.can_add_collaborators
    );
    const pagination: Ref<any> = ref({});
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Member",
          field: "member",
          isKey: true,
          width: "30%",
        },
        {
          label: "Email",
          field: "email",
          isKey: true,
          width: "30%",
        },
        {
          label: "Roles",
          field: "roles",
          isKey: true,
          width: "40%",
        },
        {
          label: "Action",
          field: "menu",
          width: "20%",
        },
      ],
      rows: [] as any,
      totalRecordCount: 0,
      sortable: {
        order: "total",
        sort: "asc",
      },
    });

    const closeMenu = () => {
      currentDropdown.value = "";
    };

    function updateHandler(e: any) {
      page.value = e;
      getAccountMembers();
    }

    async function getAccountMembers() {
      await store.dispatch("getProfile");
      table.isLoading = true;
      const _filters = removeBlankProperties({
        search: Object.values(filters.value).join("%26"),
      });
      const res = (await AccountMembersAPI.getMembers(
        page.value,
        _filters
      )) as any;

      if (res) {
        tableData.value = res.data.account_members;
        pagination.value = res.data.meta.pagination;
        setTable(tableData.value);
      }
      table.isLoading = false;
    }
    async function deleteMember(id: string) {
      AccountMembersAPI.deleteMember(id)
        .then(() => {
          getAccountMembers();
          createToastAxelerate(
            "Account Members",
            "The member has been removed. ",
            "success"
          );
        })
        .catch(() => {
          createToastAxelerate(
            "Account Members",
            "An error has ocurred. ",
            "danger"
          );
        });
      closeMenu();
    }
    async function resendInvitation(id: string) {
      AccountMembersAPI.resendInvitation(id)
        .then(() => {
          getAccountMembers();
          createToastAxelerate(
            "Account Members",
            "Invitation has been sent. ",
            "success"
          );
        })
        .catch(() => {
          createToastAxelerate(
            "Account Members",
            "An error has ocurred. ",
            "danger"
          );
        });
      closeMenu();
    }

    const InviteMember = () => {
      if (canAddMembers.value || !hascompanyPlan.value) {
        openModal.value = !openModal.value;
      } else {
        showModalUpgrade.value = true;
      }
    };

    const openDropDown = (data: any) => {
      currentDropdown.value = data.id;
    };

    function setTable(rows: Array<any>) {
      table.rows = rows;
      table.totalRecordCount = rows.length;
    }

    function searchTitle(event: any) {
      const value = event.target.value.toLowerCase();

      const data = tableData.value.filter(function (row) {
        return row.email.toLowerCase().includes(value);
      });
      setTable(data);
    }

    watchEffect(() => {
      filters.value = removeBlankProperties({
        search: inputValue.value,
      });
    });

    watch(inputValue, (newVal) => {
      if (newVal) {
        page.value = 1;
      }
    });

    onMounted(() => {
      getAccountMembers();
    });

    return {
      table,
      closeMenu,
      searchTitle,
      openDropdown,
      currentDropdown,
      openDropDown,
      openModal,
      client,
      getAccountMembers,
      deleteMember,
      resendInvitation,
      page,
      pagination,
      updateHandler,
      filters,
      InviteMember,
      inputValue,
      showModalUpgrade,
    };
  },
});
