
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import Header from "@/components/header/index.vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import UserSettings from "../user-settings/index.vue";
import AccountMembersView from "../account-members-view/index.vue";
import AccountBillingView from "../account-billing-view/index.vue";
import NotificationSettings from "@/components/notificationSettings/index.vue";
import SettingsJobNotifications from "@/components/settingsJobNotifications/index.vue";
import BoardConfig from "../../components/boardConfig/index.vue";
import ServicesTable from "@/components/servicesTable/index.vue";

export default defineComponent({
  name: "AccountSettingsV2",
  components: {
    Header,
    UserSettings,
    AccountMembersView,
    AccountBillingView,
    NotificationSettings,
    SettingsJobNotifications,
    BoardConfig,
    ServicesTable,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const activeTab = ref("");
    const path = computed(() => route.params.tab);
    const profile = computed(() =>
      store.state?.profile?.data?.roles?.includes("sourcing_recruiter")
    );
    const tabs = ref([{}]);

    if (profile.value) {
      tabs.value = [
        {
          value: "Account settings",
          key: "settings",
        },
        {
          value: "Job board",
          key: "job-board",
        },
        {
          value: "Notifications",
          key: "notifications",
        },
      ];
    } else {
      tabs.value = [
        {
          value: "Account Members",
          key: "members",
        },
        {
          value: "Account Settings",
          key: "settings",
        },

        {
          value: "Billing",
          key: "billing",
        },
        {
          value: "Job Board",
          key: "job-board",
        },
        {
          value: "Job Notifications",
          key: "job-notifications",
        },

        {
          value: "Notifications",
          key: "notifications",
        },
        {
          value: "Services",
          key: "services",
        },
      ];
    }

    const clickTab = (index: any) => {
      activeTab.value = index;
      router.replace({
        params: { tab: activeTab.value },
      });
    };

    watch(path, (newVal) => {
      clickTab(newVal);
    });

    onMounted(() => {
      if (!path.value) {
        clickTab("settings");
      } else {
        clickTab(path.value);
      }
    });

    return {
      tabs,
      activeTab,
      clickTab,
    };
  },
});
