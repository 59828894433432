/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import { client } from "../config";

export default class StripeTransactionsAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = client;
  }

  async getStripeTransactions(page: number, filters: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/stripe_transactions?page=${page}&${makeFilter(filters)}`
    );
  }

  async getStripeSuscriptions(): Promise<unknown> {
    return this.axiosInstance.get("/company_subscriptions");
  }
}
export const StripeTransactionsAPI = new StripeTransactionsAPIService();
