
import { computed, defineComponent, onMounted } from "vue";
import Navbar from "../../components/navBarJobBoard/index.vue";
import ApplyJobForm from "../../components/applyJobForm/index.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Candidate Onboarding",
  components: {
    Navbar,
    ApplyJobForm,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const profile = computed(() => store.state.profile.data);

    onMounted(async () => {
      await store.dispatch("getProfile");
    });

    /* async function setIdTalent(params:type) {
   store.
  } */

    return {};
  },
});
