import router from "@/router";
import { login } from "@/services/login";
import { useCookies } from "vue3-cookies";
import { ActionTree } from "vuex";

import { RootState } from "../types";
import { AuthState } from "./types";

const { cookies } = useCookies();

export const actions: ActionTree<AuthState, RootState> = {
  async loginUser({ commit }, user) {
    commit("SET_LOADING", true);
    commit("SET_LOGINERROR", false);
    const data = await login(user.email, user.password);
    if (data) {
      const company_id = data.session.user.company
        ? data.session.user.company.id
        : "";
      const company_name = data.session.user.company
        ? data.session.user.company.name
        : "";
      cookies.set("token", data.session.token, "15d", "/", ".axelerate.io");
      await commit("SET_LOGIN", true);
      await commit("SET_USERID", data.session.user.id, { root: true });
      await commit("SET_USEREMAIL", data.session.user.email, { root: true });
      await commit("SET_USERFIRSTNAME", data.session.user.first_name, {
        root: true,
      });
      await commit("SET_USERLASTNAME", data.session.user.last_name, {
        root: true,
      });
      await commit("SET_USERROLE", data.session.user.roles, { root: true });
      await commit("SET_COMPANYID", company_id, { root: true });
      await commit("SET_COMPANYNAME", company_name, { root: true });
      await commit("SET_PRODUCTS", data.session.user.company_products, {
        root: true,
      });
      await commit(
        "SET_PAYMENTS",
        data.session.user.company
          ? data.session.user.company.payment_methods.data
          : null,
        {
          root: true,
        }
      );
      if (data.session.user.roles[0] === "talent") {
        await commit("SET_CANDIDATEID", data.session.user.candidate.id, {
          root: true,
        });

        /** Verify if talent has filled profile **/
        if (data.session.user.has_complete_profile) {
          window.location.replace(process.env.VUE_APP_JSE_URL);
        } else {
          await commit("SET_GOTOONBOARDING", true, { root: true });
          window.location.replace(
            `${process.env.VUE_APP_JSE_URL}/candidate-onboarding/${data.session.user.candidate.id}`
          );
        }
      } else {
        await commit("SET_GOTOONBOARDING", false, { root: true });
        router.push({
          name: "Home",
          query: {
            redirect_path: user.redirect_path,
          },
        });
      }
    } else {
      commit("SET_LOGINERROR", true);
    }
    commit("SET_LOADING", false);
  },
  logOut({ commit }) {
    cookies.remove("token", "/", ".axelerate.io");
    commit("SET_LOGIN", false);
    commit("SET_USERID", "", { root: true });
    commit("SET_USEREMAIL", "", { root: true });
    commit("SET_USERFIRSTNAME", "", { root: true });
    commit("SET_USERLASTNAME", "", { root: true });
    commit("SET_USERROLE", "", { root: true });
    commit("SET_COMPANYID", "", { root: true });
    commit("SET_COMPANYNAME", "", { root: true });
    router.push("/login");
  },
  logOutJSE({ commit }) {
    cookies.remove("token", "/", ".axelerate.io");
    commit("SET_LOGIN", false);
    commit("SET_USERID", "", { root: true });
    commit("SET_USEREMAIL", "", { root: true });
    commit("SET_USERFIRSTNAME", "", { root: true });
    commit("SET_USERLASTNAME", "", { root: true });
    commit("SET_USERROLE", "", { root: true });
    commit("SET_COMPANYID", "", { root: true });
    commit("SET_COMPANYNAME", "", { root: true });
    window.location.replace(process.env.VUE_APP_JSE_URL);
  },
  setLoginError({ commit }, value: boolean) {
    commit("SET_LOGINERROR", value);
  },
};
