
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { AccountMembersAPI } from "@/services/accountMembers/index";
import AccontMembersTable from "../../components/accountMembersTable/index.vue";
import { createToastAxelerate } from "@/utils/toast";

const AccountMembersView = defineComponent({
  name: "AccountMembersView",
  components: {
    AccontMembersTable,
  },
  setup() {
    const store = useStore();
    const isLegacy = computed(() => store.state.profile.data.company.is_legacy);
    const planName = computed(
      () => store.state.profile.data.active_plan.plan_name
    );
    const analytics = ref("");

    onMounted(() => {
      AccountMembersAPI.getAnalytics()
        .then((response: any) => {
          if (
            !isLegacy.value &&
            planName.value !== "Growth" &&
            response.data.active_collaborators_limit > 0
          ) {
            analytics.value = `${response.data.active_collaborators}/${response.data.active_collaborators_limit}`;
          }
        })
        .catch((error) => {
          createToastAxelerate(
            "Get analytics",
            "An error has occurred",
            "danger"
          );
        });
    });
    return { analytics };
  },
});
export default AccountMembersView;
