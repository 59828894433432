
import { defineComponent } from "vue";

const Button = defineComponent({
  name: "Button",
  emits: ["onClick"],
  props: {
    variant: {
      type: String,
      default: "",
    },
    labelSize: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    isClick: {
      type: Boolean,
      default: true,
    },
    sizeLabel: {
      type: String,
      default: "text-base",
    },
  },
  setup(props, context) {
    const onClick = () => {
      if (props.isClick) {
        context.emit("onClick");
      }
    };

    const buildStyleByVariant = (variant: string, sizeText: string) => {
      switch (variant) {
        case "contained":
          return "btn-filled-sm flex flex-row items-center disabled:opacity-50 justify-between truncate sm:text-base bg-primary rounded-full transition duration-150 ease-in";
        case "outlined":
          return "btn-outline-sm flex flex-row items-center disabled:opacity-50 justify-between px-4 py-2 text-primary truncate sm:text-base border-2 rounded-full transition duration-150 ease-in";
        case "contained-lg":
          return "btn-filled-lg flex flex-row items-center disabled:opacity-50 justify-between truncate sm:text-base bg-primary rounded-full transition duration-150 ease-in";
        case "outlined-lg":
          return "btn-outline-lg flex flex-row items-center disabled:opacity-50 justify-between px-4 py-2 text-primary truncate sm:text-base border-2 rounded-full transition duration-150 ease-in";
        case "contained-full":
          return "btn-filled-lg items-center justifydisabled:opacity-50 text-white text-sm sm:text-base bg-primary rounded-full py-2 w-full transition duration-150 ease-in h-14";
        default:
          return `btn-text flex flex-row items-center p-2 text-primary hover:bg-gray-100 disabled:text-white ${sizeText}`;
      }
    };
    return { onClick, buildStyleByVariant };
  },
});

export default Button;
