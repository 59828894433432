
import { defineComponent, ref, computed, Ref, onMounted } from "vue";
import { useStore } from "vuex";

const NavBar = defineComponent({
  name: "NavBar",
  setup() {
    const isMenuOpen = ref(false);
    const store = useStore();
    const roles = computed(() => store.state.profile?.data?.roles);
    const managedJobs = computed(() => store.state.profile?.data?.managed_jobs);
    const services: Ref<Array<string>> = ref([]);
    const products = ref([""]);
    const closeMenu = () => {
      isMenuOpen.value = false;
    };
    const hasPastDue = ref(true);

    const backToPlatform = (product: string) => {
      if (product === "sourcing_admin") {
        window.location.replace(
          `https://sourcing${process.env.VUE_APP_CANARY_SUFFIX}.axelerate.io`
        );
      } else if (product === "workforce_admin") {
        window.location.replace(
          `https://workforce${process.env.VUE_APP_CANARY_SUFFIX}.axelerate.io`
        );
      }
    };

    function setProductsToShow() {
      for (let i = 0; i < products.value.length; i++) {
        services.value.push(products.value[i]);
      }
    }

    onMounted(() => {
      if (
        roles.value.includes("sourcing_admin") ||
        roles.value.includes("workforce_admin")
      ) {
        products.value = roles.value;
      } else {
        products;
        products.value = ["sourcing_admin"];
      }
      setProductsToShow();
      if (
        managedJobs.value.filter(
          (e: any) => e.subscription_status === "inactive"
        ).length > 0
      ) {
        hasPastDue.value = true;
      } else {
        hasPastDue.value = false;
      }

    });

    return {
      isMenuOpen,
      roles,
      closeMenu,
      backToPlatform,
      services,
      hasPastDue,
    };
  },
});
export default NavBar;
