/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { client } from "../config";

export async function stripeAddCard() {
  try {
    const { data } = await client.get("/stripe/add_card?platform=id");
    return data.url;
  } catch (err) {
    return null;
  }
}

export async function getProfile() {
  try {
    const { data } = await client.get("/profiles");
    return data;
  } catch (err) {
    return null;
  }
}

export default class BillingAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = client;
  }

  async getPaymentMethods(): Promise<unknown> {
    return this.axiosInstance.get("/payment_methods");
  }
  async getStripeCustomer(): Promise<unknown> {
    return this.axiosInstance.get("/stripe/customer");
  }
  async stripeAddCard(): Promise<unknown> {
    return this.axiosInstance.get("/stripe/add_card?platform=sourcing");
  }
  async stripeRemoveCard(cardId: string): Promise<unknown> {
    return this.axiosInstance.get(`/stripe/remove_card/${cardId}`);
  }
  async stripeUpdateDefaultCard(cardId: string): Promise<unknown> {
    return this.axiosInstance.get(`/stripe/update_default_card/${cardId}`);
  }
  async savePaymentContacts(companyId: string, body: any): Promise<any> {
    return this.axiosInstance.put(`/companies/${companyId}`, body);
  }
}
export const BillingAPI = new BillingAPIService();
