
import { useField, useForm } from "vee-validate";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRoute } from "vue-router";
import * as yup from "yup";
import TextField from "@/components/elements/textField/index.vue";
import { ForgotPasswordAPI } from "../../services/forgotPassword/index";
import { createToastAxelerate } from "../../utils/toast";
import Spinner from "@/components/elements/spinner/index.vue";

export default defineComponent({
  name: "Reset Password",
  components: {
    TextField,
    Spinner,
  },
  setup() {
    const route = useRoute();
    const token = ref(false);
    const step = ref(1);
    const loading = ref(false);
    const schema = yup.object({
      password: yup
        .string()
        .required()
        .matches(
          /[a-z]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /[A-Z]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /[@$!%*#?&."'<>+-]+/,
          "Must contain at least one uppercase, special character and number"
        )
        .matches(
          /\d+/,
          "Must contain at least one uppercase, special character and number"
        )
        .min(8),
      password_confirmation: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    });

    const fieldErrors: Ref<Array<string>> = ref([]);
    const { value: password_confirmation } = useField("password_confirmation");
    const { value: password } = useField("password");
    const { meta, handleSubmit } = useForm({
      initialValues: {
        password_confirmation: "",
        password: "",
      },
      validationSchema: schema,
    });

    const onSubmit = handleSubmit((values) => {
      resetPassword(values);
    }, invalidSubmit);

    function invalidSubmit({ errors }: any) {
      fieldErrors.value = errors;
    }

    async function resetPassword(value: any) {
      loading.value = true;
      const body = {
        password: value.password,
        password_confirmation: value.password_confirmation,
        token_id: route.query.token,
      };
      ForgotPasswordAPI.resetPassword(body)
        .then((response) => {
          step.value = 2;
          loading.value = false;
        })
        .catch((err) => {
          createToastAxelerate(
            "Set Password",
            err.response.data.errors[0],
            "danger"
          );
          loading.value = false;
        });
    }

    onMounted(() => {
      token.value = route.query.token ? true : false;
    });

    return {
      onSubmit,
      confirm,
      password,
      password_confirmation,
      meta,
      token,
      step,
      fieldErrors,
      resetPassword,
      loading,
    };
  },
});
