import axios from "axios";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/vnd.axelerate.v1+json",
    "X-Platform": "",
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use(
  (config: any) => {
    const token = cookies.get("token");
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const talent = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    Accept: "application/vnd.axelerate.v1+json",
    "X-Platform": "talent",
    "Content-Type": "application/json",
  },
});

talent.interceptors.request.use(
  (config: any) => {
    const token = cookies.get("token");
    if (token) {
      config.headers.authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error)
);
