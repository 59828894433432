
import { defineComponent, onBeforeMount, ref } from "vue";
import moment from "moment";
import { numberWithCommas } from "../../utils/functions";
import { useRouter } from "vue-router";

const boardCard = defineComponent({
  name: "boardCard",

  components: {},
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const red = ref("");
    const router = useRouter();
    const host = process.env.VUE_APP_JOB_BOARD_URL;
    const timeAgo = (date: any) => {
      return moment(date).fromNow();
    };
    const branding = {
      accentColor: "",
    };
    const currentHost = window.location.host,
      domain = currentHost.split(":")[0],
      subdomainComponents = domain.split("."),
      subdomain = subdomainComponents[0];

    onBeforeMount(() => {
      if (["mtydigitalhub", "mtydigitalhub-test"].includes(subdomain)) {
        let hubBranding = {
          accentColor: "text-color",
        };

        Object.assign(branding, hubBranding);
      }
    });

    return {
      red,
      timeAgo,
      numberWithCommas,
      router,
      host,
      branding,
    };
  },
});

export default boardCard;
