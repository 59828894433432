import { AxiosInstance } from "axios";
import { client } from "../config";

export default class CompleteAccountAPIService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = client;
  }

  async getCompleteAccount(token: any): Promise<unknown> {
    return this.axiosInstance.get(`/get_user/${token}`);
  }

  async updateAccount(userId: string, userData: any): Promise<unknown> {
    return this.axiosInstance.put(`/update_user/${userId}`, userData);
  }

  async getCompanyIndustries(): Promise<unknown> {
    return this.axiosInstance.get("/company_industries");
  }
}
export const CompleteAccountAPI = new CompleteAccountAPIService();
