
import { defineComponent, ref, watchEffect } from "vue";

const AutocompleteLocation = defineComponent({
  name: "AutocompleteLocation",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    locationName: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const address = ref("");
    const options = {
      componentRestrictions: {
        country: [
          "us",
          "mx",
          "ca",
          "es",
          "co",
          "cl",
          "cr",
          "ec",
          "gt",
          "hn",
          "ni",
          "pa",
          "pe",
          "pr",
          "py",
          "sv",
          "ve",
        ],
      },
    };
    /* eslint-disable @typescript-eslint/no-explicit-any */
    function setPlace(event: any) {
      const data = {
        place_id: event.place_id,
        address: event.formatted_address,
      };
      emit("update:modelValue", data);
    }

    function clearInput() {
      address.value = "";
      emit("update:modelValue", null);
    }

    watchEffect(() => {
      if (props.locationName) {
        address.value = props.locationName;
      }
    });

    return {
      setPlace,
      address,
      clearInput,
      options,
    };
  },
});
export default AutocompleteLocation;
