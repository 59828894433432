
import { defineComponent } from "vue";

export default defineComponent({
  name: "Checkbox",
  props: {
    selected: Boolean,
    width: {
      type: String,
      default: "5",
    },
    height: {
      type: String,
      default: "5",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:selected"],
  setup(props, { emit }) {
    function changeSelection() {
      emit("update:selected", !props.selected);
    }

    return { changeSelection };
  },
});
