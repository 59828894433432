import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "flex flex-row items-center justify-center py-6 mt-2" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "pl-5 text-sm font-bold" }
const _hoisted_7 = {
  key: 1,
  class: "text-red-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.hasPastDue)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (product, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                class: "inline-block text-left text-black px-6"
              }, [
                _createElementVNode("button", {
                  class: "flex flex-row items-center",
                  "aria-haspopup": "true",
                  onClick: ($event: any) => (_ctx.backToPlatform(product))
                }, [
                  _createElementVNode("img", {
                    src: require(`@/assets/${product}.svg`)
                  }, null, 8, _hoisted_5),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(product == "sourcing_admin"
                  ? "Talent Sourcing"
                  : "Workforce Solutions"), 1)
                ], 8, _hoisted_4)
              ]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, "Please update your payment methods"))
    ])
  ]))
}