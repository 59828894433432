
import { defineComponent, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import MenuDropDown from "../menuDropdown/index.vue";
import NavBar from "../navBar/index.vue";

const Header = defineComponent({
  name: "Header",
  emits: ["logOut"],
  components: {
    MenuDropDown,
    NavBar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const profile = computed(() => store.state.profile.data);
    const user = ref({});
    const showModal = ref(false);

    const logOut = () => {
      store.dispatch("logOut");
    };

    if (profile?.value?.first_name) {
      let intercomScript = document.createElement("script");
      intercomScript.text = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${process.env.VUE_APP_INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

      document.head.appendChild(intercomScript);

      (window as any).Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
        name: `${profile.value.first_name} ${profile.value.last_name}`,
        email: profile.value.email,
        company: {
          id: profile.value.company.id,
          name: profile.value.company.name,
          plan: profile.value.company.last_subscription?.nickname
            ? profile.value.company.last_subscription.nickname
            : "",
          website: `${profile.value.company.company_products[0]} ${
            profile.value.company.company_products[1] || ""
          }`,
          industry: profile.value.company.country,
        },
        user_hash: profile.value.intercom_hash,
      });
    }

    return { user, showModal, logOut, profile, router };
  },
});

export default Header;
