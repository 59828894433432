<template>
  <div class="flex flex-row space-x-3 justify-between">
    <div class="flex flex-row">
      <span class="text-lg font-semibold pb-3">Organization details</span>
      <div class="pl-1 pt-1"></div>
    </div>
    <button
      type="button"
      @click="showModal = !showModal"
      class="text-sm font-light text-primary"
    >
      Edit
    </button>
  </div>
  <div class="pt-3 flex flex-row">
    <div
      class="m-1 mr-2 w-12 h-12 relative flex justify-center items-center rounded-full bg-primary text-xl text-white uppercase"
    >
      {{ getInitials(userRes && userRes.visible_name) }}
    </div>
    <div class="pl-3 flex flex-col">
      <span class="text-lg font-semibold">{{
        userRes && userRes.visible_name
      }}</span>
      <span class="text-xs font-light text-gray-400">
        {{ userRes.company_industry?.name }}</span
      >
      <span class="text-sm font-light text-gray-400 uppercase mt-1">
        {{ userRes.country }}
      </span>
      <!-- <div class="flex flex-row space-x-3 items-center">
        <span class="text-sm text-primary font-light">{{
          `${userRes && userRes.email ? userRes.email : "-"}`
        }}</span>
        <span class="text-xs text-gray-400 font-light">{{
          `+ ${userRes && userRes.phone}`
        }}</span>
      </div> -->
    </div>
  </div>
  <ReusableModal :show="showModal" @close="showModal = false">
    <ModalUpdateCompany
      :openModal="showModal"
      @close="showModal = false"
    ></ModalUpdateCompany>
  </ReusableModal>
</template>

<script>
import { computed, defineComponent, ref, onBeforeMount } from "vue";
import ReusableModal from "../../components/reusableModal/index.vue";
import { useStore } from "vuex";
import { getInitials } from "../../utils/functions";
import ModalUpdateCompany from "../modalUpdateCompanyInfo/index.vue";

const CompanyDetails = defineComponent({
  name: "settingsCompanyDetails",
  components: {
    ReusableModal,
    ModalUpdateCompany,
  },
  setup() {
    const showModal = ref(false);
    const store = useStore();
    const userRes = computed(() => store.state.profile.data.company);

    return {
      showModal,
      userRes,
      getInitials,
    };
  },
});

export default CompanyDetails;
</script>
