
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { ColorPicker } from "vue-accessible-color-picker";

const ChooseColorPicker = defineComponent({
  name: "ChooseColorPicker",
  emits: ["update:modelValue"],
  components: {
    ColorPicker,
  },
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const colorSelected = ref("");
    const updateColor = (eventData: any) => {
      var _color =
        String(eventData.colors.hex).length > 8
          ? String(eventData.colors.hex).slice(0, -2)
          : eventData.colors.hex;
      context.emit("update:modelValue", _color);
    };

    return {
      updateColor,
      colorSelected,
    };
  },
});

export default ChooseColorPicker;
