/* eslint-disable @typescript-eslint/no-explicit-any */

// import { formatDistanceStrict } from "date-fns";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export const getInitials = (name: any): any => {
  let initials = name.split(" ");
  if (initials.length > 1) {
    initials = initials?.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    initials = name.substring(0, 2);
  }
  return initials.toUpperCase();
};

export const buildColorStep = (option: string) => {
  switch (option) {
    case "Talent Pool":
      return { backgroundColor: "#E5F8FF", color: "#00A7E5" };
    case "Documents":
      return { backgroundColor: "#F2F5FF", color: "#0538E0" };
    case "Offer":
      return { backgroundColor: "#FFF8F2", color: "#E56900" };
    case "Hiring":
      return { backgroundColor: "#F2FFF9", color: "#00E574" };
    case "Interview":
      return { backgroundColor: "#FEFAF1", color: "#CB911A" };
    case "Phone Screen":
      return { backgroundColor: "#FBF2FF", color: "#9D04E1" };
    case "Tech Interview":
      return { backgroundColor: "#FFF2FC", color: "#E500B5" };
    case "Challenge":
      return { backgroundColor: "#FFF2F2", color: "#E50000" };
    default:
      break;
  }
};

export const getCompanyName = (str: string) => {
  return str.substring(str.lastIndexOf("@") + 1, str.lastIndexOf("."));
};

export const validateEmail = (value: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

export const numberWithCommas = (x: number) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

//export const timeAgo = (date: string) => {
//  return formatDistanceStrict(new Date(date), new Date(), {
//    addSuffix: true,
//  });
//};

export const getFormatStringDate = (d: string) => {
  const date = new Date(d);

  return date.toLocaleString("en-US", {
    month: "long",
    year: "numeric",
    day: "numeric",
  });
};
export const isEmptyOrNull = (value: string, type = "string") => {
  let val = true;
  if (!value) {
    val = false;
  } else if (typeof value !== type) {
    val = false;
  }
  return val;
};

export const hexToRGBA = (hex: any, alpha: any, from?: any) => {
  // remove invalid characters
  hex = hex.replace(/[^0-9a-fA-F]/g, "");

  if (hex.length < 5) {
    // 3, 4 characters double-up
    hex = hex
      .split("")
      .map((s: any) => s + s)
      .join("");
  }

  // parse pairs of two
  const rgba = hex.match(/.{1,2}/g).map((s: string) => parseInt(s, 16));

  // alpha code between 0 & 1 / default 1
  rgba[3] =
    rgba.length > 3 ? parseFloat((rgba[3] / 255) as any).toFixed(2) : alpha;

  return "rgba(" + rgba.join(", ") + ")";
};

export const isLater = (str1: string, str2: string) => {
  if (isEmptyOrNull(str1, "object") && isEmptyOrNull(str2, "object")) {
    return new Date(str1) > new Date(str2);
  }
  return false;
};
