
import * as yup from "yup";
import { useRouter, useRoute } from "vue-router";
import {
  computed,
  defineComponent,
  onMounted,
  Ref,
  ref,
  watchEffect,
  watch,
} from "vue";
import TextArea from "../textArea/index.vue";
import TextField from "../textField/index.vue";
import CheckBoxGroup from "../checkboxGroup/index.vue";
import { useField, useForm } from "vee-validate";
import { JobBoardService } from "@/services/jobBoard/index";
import DragAndDropFile from "../../components/dragFile/index.vue";
import EducationArrayField from "../educationFieldArray/index.vue";
import WorkExperienceArrayField from "../../components/workExperienceFieldArray/index.vue";
import ApplyJobFormSummary from "@/components/applyJobFormSummary/index.vue";
import CurrencyField from "../currencyField/index.vue";
import DropDown from "../selectDropdown/index.vue";
import { createToastAxelerate } from "@/utils/toast";
import { useState } from "@/composables/useState";
import Tooltip from "../toolTip/index.vue";
import Chips from "../chips/index.vue";
import { useStore } from "vuex";
import { userAPI } from "@/services/user";
import { useCookies } from "vue3-cookies";
import Loader from "@/components/loader/index.vue";
import AutocompleteLocation from "@/components/autocompleteLocation/index.vue";

const ApplyJobForm = defineComponent({
  name: "ApplyJobForm",
  emits: ["isValidForm", "jobApplied", "isSavingJob", "jobInfo"],
  components: {
    CheckBoxGroup,
    Chips,
    DropDown,
    TextArea,
    TextField,
    CurrencyField,
    DragAndDropFile,
    EducationArrayField,
    Tooltip,
    WorkExperienceArrayField,
    ApplyJobFormSummary,
    Loader,
    AutocompleteLocation,
  },
  props: {
    job: {
      type: Object,
      default: () => ({
        id: "",
      }),
    },
    isClicked: {
      type: Boolean,
      default: false,
    },
    application: {
      type: String,
      default: "jse",
    },
  },
  setup(props, context) {
    const { cookies } = useCookies();
    const step = ref(1);
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const defaultSkills: Ref<any> = ref([]);
    const selectedSkills: Ref<any> = ref([]);
    const skills = ref([]);
    const interests = ref([]);
    const preferred_work_types = ref([]);
    const preferred_work_types_Aux = ref();
    const fileResume = ref();
    const fileOther = ref();
    const auxFile = ref("");
    const auxOtherFile = ref("");
    const isLoadingData = ref(true);
    const isEditing = ref(false);
    const profile = computed(() => store.state.profile.data);
    const previousUrl = store.getters.getPreviousUrl;
    const place_id = ref("");
    const locationName = ref("");
    const candidate_location = ref("");

    const auxWorkHistories = [
      {
        start_date: null,
        end_date: null,
        job_title: "",
        company_name: "",
        industry: "",
        is_current: false,
      },
    ];
    const auxEducationHistories = [
      {
        start_date: null,
        end_date: null,
        degree: "",
        learning_center: "",
        is_current: false,
      },
    ];
    const [isLoadingButton, setLoadingButton] = useState(false);
    const optionCurrencies = [
      { id: "USD", name: "Dollar (USD)" },
      { id: "MXN", name: "Mexican Peso (MXN)" },
    ];
    const company = computed(
      () => props.job.company.company_branding?.domain_name
    );
    const jobId = computed(() => props.job.id);
    const isFileUploaded = ref(false);
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const resume = ref({});
    const buttonRef = ref(null);
    const workTypes = [
      { label: "Remote", value: "remote" },
      { label: "Hybrid", value: "hybrid" },
      { label: "On-site", value: "on_site" },
      { label: "Open to relocation", value: "open_to_relocation" },
    ];
    const fileNameResume = ref("");
    const fileNameOther = ref("");
    const schema = yup.object({
      first_name: yup.string().label("First Name").required(),
      last_name: yup.string().label("Last Name").required(),
      email: yup.string().label("Email").required().email(),
      phone: yup
        .string()
        .label("Phone")
        .required()
        .matches(phoneRegExp, "Enter a valid phone number")
        .min(10),
      candidate_work_histories: yup.array().of(
        yup.object().shape({
          start_date: yup.date().nullable().label("Start date"),
          end_date: yup.date().nullable().label("End date"),
          job_title: yup.string().nullable().label("Job title"),
          company_name: yup.string().label("Company").nullable(),
          is_current: yup.boolean().nullable(),
          industry: yup.string().nullable().label("Industry"),
        })
      ),
      candidate_education_histories: yup.array().of(
        yup.object().shape({
          start_date: yup.date().nullable().label("Start date").nullable(),
          end_date: yup.date().nullable().label("End date"),
          learning_center: yup.string().nullable().label("School"),
          degree: yup.string().nullable().label("Education"),
          is_current: yup.boolean().nullable(),
        })
      ),
      portfolio: yup.string().label("Portfolio URL").nullable(),
      summary: yup.string().label("Summary").nullable(),
      link: yup.string().label("Link").nullable(),
      cover_letter: yup.string().label("Cover Letter").nullable(),
      current_salary: yup.string().label("Current Salary").nullable(),
      salary_currency: yup.string().label("Currency Salary").nullable(),
      salary_expectation: yup.string().label("Current Salary").nullable(),
      currency_current_salary: yup.string().label("Currency Salary").nullable(),
    });
    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: email } = useField("email");
    const { value: phone } = useField("phone");
    const { value: summary } = useField("summary");
    const { value: link } = useField("link");
    const { value: portfolio } = useField("portfolio");
    const { value: cover_letter } = useField("cover_letter");
    const visibleSkills: Ref<any> = ref([]);
    const hiddenSkills: Ref<any> = ref([]);
    const showMoreSkills: Ref<any> = ref(false);
    const industries = ref([]);

    const { value: salaryExpectation } = useField(
      "salary_expectation",
      yup.string().required().label("Salary expectation").nullable(),
      {
        initialValue: "0",
      }
    );
    const { value: currentSalary } = useField(
      "current_salary",
      yup.string().required().label("Current").nullable(),
      {
        initialValue: "0",
      }
    );
    const { value: currencySalary } = useField("salary_currency");
    const { value: currencyCurrentSalary } = useField(
      "current_salary_currency"
    );

    const { values, meta, handleSubmit, errors, validate, setValues } = useForm(
      {
        validationSchema: schema,
        initialValues: {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          candidate_work_histories: [
            {
              start_date: null,
              end_date: null,
              job_title: "",
              company_name: "",
              industry: "",
              is_current: false,
            } as any,
          ],
          candidate_education_histories: [
            {
              start_date: null,
              end_date: null,
              degree: "",
              learning_center: "",
              is_current: false,
            } as any,
          ],
          portfolio: "",
          summary: "",
          link: "",
          cover_letter: "",
          current_salary: null,
          salary_expectation: null,
          salary_currency: "",
          currency_current_salary: "",
        },
      }
    );

    const applyJob = (values: any) => {
      const buildParams = {
        candidate: {
          ...values,
          place_id: place_id.value,
          application: props.application,
          resume: resume.value,
          current_salary_currency: values.currency_current_salary,
          salary_expectation: parseFloat(
            values.salary_expectation?.replace(/,/g, "") || ""
          ),
          current_salary: parseFloat(
            values.current_salary?.replace(/,/g, "") || ""
          ),
        },
      };

      JobBoardService.applyJob(company.value, jobId.value, buildParams)
        .then((response: any) => {
          const data = {
            candidate: {
              place_id: place_id.value,
              candidate_education_histories:
                values.candidate_education_histories,
              candidate_work_histories: values.candidate_work_histories,
              skills: skills.value,
              interests: interests.value,
              preferred_work_types: preferred_work_types.value,
            },
          };
          let candidateId = response.data.candidate.id;
          JobBoardService.updateCandidate(
            company.value,
            jobId.value,
            data,
            candidateId
          )
            .then(() => {
              store.dispatch("setOnboardingEmail", values.email);
              store.dispatch("setOnboardingFirstName", values.first_name);
              store.dispatch("setOnboardingLastName", values.last_name);
              context.emit("jobApplied");
              context.emit("isSavingJob", false);
              setLoadingButton(false);
            })
            .catch((err) => {
              const {
                response: {
                  data: { errors },
                },
              } = err;
              createToastAxelerate(
                "Apply Job",
                errors ? errors[0] : "An error has ocurred.",
                "danger"
              );
            });
        })
        .catch((err) => {
          setLoadingButton(false);
          context.emit("isSavingJob", false);
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Apply Job",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
    };

    const updateProfile = (values: any) => {
      const workHistories = values.candidate_work_histories.filter(function (
        element: any
      ) {
        return element.job_title !== "";
      });

      const educationHistories = values.candidate_education_histories.filter(
        function (element: any) {
          return element.degree !== "";
        }
      );
      setLoadingButton(true);
      const candidateId = profile.value.candidate.id;
      preferred_work_types.value.map((item) => {
        Object.keys(preferred_work_types_Aux.value).forEach((data) => {
          if (item && item === data) {
            preferred_work_types_Aux.value[data] = true;
          }
        });
      });
      const body = {
        candidate: {
          ...values,
          place_id: place_id.value,
          resume: resume.value,
          documents: fileOther.value,
          current_salary_currency: values.currency_current_salary,
          salary_expectation: parseFloat(
            values.current_salary?.replace(/,/g, "") || ""
          ),
          current_salary: parseFloat(
            values.current_salary?.replace(/,/g, "") || ""
          ),
          interests: interests.value,
        },
      };

      userAPI
        .updateTalent(candidateId, body)
        .then((response: any) => {
          let payload = {
            candidate: {
              place_id: place_id.value,
              preferred_work_type: preferred_work_types_Aux.value,
              candidate_education_histories: educationHistories,
              candidate_work_histories: workHistories,
              skills: selectedSkills.value,
            },
          };

          userAPI
            .updateTalentHistory(candidateId, payload)
            .then(async (response) => {
              await store.dispatch("getProfile");
              setLoadingButton(false);

              if (previousUrl) {
                router.push(`/${previousUrl}`);
              } else {
                router.push("/");
              }
            })
            .catch(() => {
              setLoadingButton(false);
              createToastAxelerate(
                "Error saving information",
                "Please try again in a few minutes",
                "danger"
              );
            });
        })
        .catch(() => {
          setLoadingButton(false);
          createToastAxelerate(
            "Error saving information",
            "Please try again in a few minutes",
            "danger"
          );
        });
    };

    const onSubmit = handleSubmit((values) => {
      context.emit("isSavingJob", true);
      setLoadingButton(true);
      if (
        route.name === "CandidateOnboarding" ||
        route.name === "Edit Candidate"
      ) {
        updateProfile(values);
      } else {
        applyJob(values);
      }
    });

    const setOnboardingValues = () => {
      if (route.name === "CandidateOnboarding") {
        setValues({
          first_name: profile.value.first_name,
          last_name: profile.value.last_name,
          email: profile.value.email,
          candidate_work_histories: auxWorkHistories as any,
          candidate_education_histories: auxEducationHistories as any,
        });
        isLoadingData.value = false;
      }
    };

    const stepStages = ref([
      {
        number: 1,
        title: "General information",
        description: "Your background and experience",
      },
      {
        number: 2,
        title: "Skills",
        description: "Your relevant skills",
      },
      {
        number: 3,
        title: "Preferences",
        description: "Your expected salary and preferred work type",
      },
      {
        number: 4,
        title: "Overview",
        description: "Please review your profile",
      },
    ]);

    const setStep = (newStep: any) => {
      step.value = newStep;
    };

    const changeStep = async (value: string) => {
      window.scrollTo(0, 0);
      const { valid } = await validate();
      if (value === "next") {
        switch (step.value) {
          case 1:
            if (valid && isFileUploaded.value) {
              step.value++;
            } else {
              createToastAxelerate(
                "Required fields",
                "Fill the requires fields",
                "danger"
              );
              step.value == 1;
            }
            break;
          case 2:
            step.value++;
            break;
          case 3:
            step.value++;
            break;
        }
      } else {
        step.value--;
      }
    };

    watchEffect(() => {
      context.emit("isValidForm", isFileUploaded.value && meta.value.valid);
    });

    watchEffect(() => {
      if (props.isClicked) {
        onSubmit();
      }
    });

    watchEffect(() => {
      if (fileResume.value || isFileUploaded.value) {
        resume.value = fileResume.value;
        isFileUploaded.value = true;
        context.emit("isValidForm", isFileUploaded.value && meta.value.valid);
      } else {
        resume.value = {};
        isFileUploaded.value = false;
        context.emit("isValidForm", isFileUploaded.value && meta.value.valid);
      }
    });

    const getCandidateInfo = () => {
      if (route.name === "Edit Candidate") {
        isEditing.value = true;
        isLoadingData.value = true;
        const id = profile.value.candidate.id;

        userAPI
          .getTalent(id)
          .then((res: any) => {
            setValues({
              first_name: res.data.first_name,
              last_name: res.data.last_name,
              email: res.data.email,
              phone: res.data?.phone ? res.data.phone : "",
              summary: res.data.summary,
              link: res.data.link,
              current_salary: res.data.current_salary,
              salary_expectation: res.data.salary_expectation,
              salary_currency: res.data.salary_currency,
              currency_current_salary: res.data.current_salary_currency,
              candidate_education_histories:
                res.data?.candidate_education_histories?.length > 0
                  ? res.data?.candidate_education_histories
                  : auxEducationHistories,
              candidate_work_histories:
                res.data?.candidate_work_histories?.length > 0
                  ? res.data?.candidate_work_histories
                  : auxWorkHistories,
            });
            place_id.value = res.data.candidate_location?.location?.place_id;
            locationName.value =
              res.data.candidate_location?.location?.street_address;
            candidate_location.value =
              res.data.candidate_location?.location?.street_address;
            currentSalary.value = res.data.current_salary;
            isFileUploaded.value = res.data?.resume?.url ? true : false;
            auxFile.value = res.data.resume.url;
            auxOtherFile.value = res.data.documents.url;
            selectedSkills.value = res.data.skills;
            preferred_work_types_Aux.value = res.data.preferred_work_type;
            for (const [type, value] of Object.entries(
              res.data.preferred_work_type
            )) {
              if (value === true) {
                preferred_work_types.value.push(type as never);
              }
            }
            preferred_work_types.value = preferred_work_types.value.filter(
              (item) => item
            );
            isLoadingData.value = false;
          })
          .catch((error) => {
            isLoadingData.value = false;
          });
      } else {
        preferred_work_types_Aux.value = {
          remote: false,
          hybrid: false,
          on_site: false,
          open_to_relocation: false,
        };
      }
    };

    const setEmptyFile = () => {
      auxFile.value = "";
      isFileUploaded.value = false;
    };

    onMounted(async () => {
      setTab();
      getSkills();
      getIndustries();
      await store.dispatch("getProfile");
      getCandidateInfo();
      setOnboardingValues();
    });

    const setTab = () => {
      const tab = route.params.tab;
      if (tab) {
        switch (tab) {
          case "general-information":
            step.value = 1;
            break;
          case "skills":
            step.value = 2;
            break;
          case "preferences":
            step.value = 3;
            break;

          default:
            step.value = 1;
            break;
        }
      }
    };

    watch(store.getters.getUserFirstName, () => {
      setOnboardingValues();
    });

    async function getIndustries() {
      await JobBoardService.getCompanyIndustries().then((res: any) => {
        industries.value = res.data;
      });
    }

    const setFileNameResume = (name: any) => {
      fileNameResume.value = name;
    };

    const setFileNameOther = (name: any) => {
      fileNameOther.value = name;
    };

    const getFileName = (filename: string) => {
      if (!filename) return "undefined";
      let auxFileName = filename.substring(filename.lastIndexOf("/") + 1);
      return auxFileName.substr(0, auxFileName.lastIndexOf("."));
    };
    const getFileExtension = (filename: string) => {
      return filename ? filename.split(".").pop() : "undefined";
    };

    async function getSkills() {
      await JobBoardService.getSkills().then((res: any) => {
        defaultSkills.value = res.data.skills;
        for (let i = 0; i < 21; i++) {
          visibleSkills.value.push(defaultSkills.value[i]);
        }
        for (let i = 21; i < defaultSkills.value.length; i++) {
          hiddenSkills.value.push(defaultSkills.value[i]);
        }
      });
    }

    const updateHandler = (data: any) => {
      place_id.value = data.place_id;
      candidate_location.value = data.address;
    };

    async function showHiddenSkills() {
      showMoreSkills.value = !showMoreSkills.value;
    }

    async function pushToSelectedSkills(name: any) {
      const unique = [...selectedSkills.value];
      unique.push(name);
      selectedSkills.value = unique.filter((v, i, a) => a.indexOf(v) === i);
    }

    return {
      buttonRef,
      changeStep,
      cover_letter,
      currencyCurrentSalary,
      currencySalary,
      currentSalary,
      defaultSkills,
      email,
      errors,
      first_name,
      fileResume,
      fileOther,
      fileNameResume,
      fileNameOther,
      setFileNameResume,
      setFileNameOther,
      hiddenSkills,
      industries,
      interests,
      isFileUploaded,
      isLoadingButton,
      last_name,
      auxFile,
      auxOtherFile,
      link,
      meta,
      onSubmit,
      getFileExtension,
      getFileName,
      optionCurrencies,
      phone,
      portfolio,
      setEmptyFile,
      preferred_work_types,
      pushToSelectedSkills,
      resume,
      router,
      salaryExpectation,
      selectedSkills,
      setLoadingButton,
      showHiddenSkills,
      showMoreSkills,
      skills,
      step,
      stepStages,
      summary,
      visibleSkills,
      workTypes,
      values,
      setStep,
      isLoadingData,
      isEditing,
      locationName,
      updateHandler,
      candidate_location,
    };
  },
});

export default ApplyJobForm;
