import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: {"border-top-color":"transparent"},
      class: _normalizeClass(`absolute w-${_ctx.width} h-${_ctx.height} border-${_ctx.border} border-${_ctx.color} rounded-full animate-spin mt-${_ctx.top}`)
    }, null, 2)
  ]))
}