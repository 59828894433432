
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

const NextBilling = defineComponent({
  name: "NextBilling",
  setup() {
    const store = useStore();
    const country = computed(() => store.state.profile.data.company?.country);
    const amount = computed(() =>
      (
        store.state.profile.data.company?.last_subscription.amount / 100
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
    const date = computed(
      () => store.state.profile.data.company?.last_subscription.end_date
    );
    const plan = store.state.profile.data.company?.last_subscription.nickname;
    return {
      country,
      amount,
      date,
      plan,
    };
  },
});

export default NextBilling;
