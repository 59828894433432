
import { defineComponent } from "vue";
import { Field } from "vee-validate";

export default defineComponent({
  name: "TextField",
  inheritAttrs: false,
  components: {
    Field,
  },
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    typeInput: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
});
