
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const SuccessPayment = defineComponent({
  name: "SuccessPayment",
  setup() {
    const router = useRouter();
    const store = useStore();
    onMounted(() => {
      store.dispatch("getProfile");
    });
    return { router };
  },
});

export default SuccessPayment;
