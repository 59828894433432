<template>
  <div class="flex flex-row space-x-3 justify-between">
    <div class="flex flex-row justify-between">
      <span class="text-lg font-semibold">Personal details</span>
      <div class="pl-1 pt-1"></div>
    </div>
    <button
      type="button"
      @click="showModal = !showModal"
      class="text-sm font-light text-primary"
    >
      Edit
    </button>
  </div>
  <div class="pt-3 flex flex-row">
    <div v-if="userRes.avatar?.url" class="">
      <div
        class="m-1 w-12 h-12 relative flex justify-center items-center rounded-full text-xl text-white uppercase bg-cover"
        :style="`background-image: url(${userRes.avatar?.url})`"
      ></div>
    </div>
    <div
      v-else
      class="m-1 mr-2 w-12 h-12 relative flex justify-center items-center rounded-full bg-primary text-lg font-semibold text-white uppercase"
    >
      {{
        getInitials(
          `${userRes && userRes.first_name} ${userRes && userRes.last_name}`
        )
      }}
    </div>
    <div class="pl-3 flex flex-col space-y-2">
      <span class="text-lg font-semibold">{{
        `${userRes && userRes.first_name} ${userRes && userRes.last_name}`
      }}</span>
      <span class="text-sm font-light text-gray-500">{{
        userRes && userRes.email
      }}</span>
    </div>
  </div>
  <div>
    <ReusableModal :show="showModal" @close="showModal = false">
      <form @submit="onSubmit">
        <div class="flex flex-col mb-6">
          <span class="text-lg font-semibold py-3">Edit personal details</span>
        </div>
        <div class="">
          <UploadFileButton
            :url="`${
              userRes?.avatar?.url
                ? userRes?.avatar?.url
                : require('@/assets/user.png')
            }`"
            v-model="profilePicture"
            name="background"
            height="h-44"
            width="w-44"
            className="absolute rounded-full flex flex-col justify-center items-center bg-purple-200 text-gray-700 relative justify-center items-center w-12 h-12 bottom-1 right-0 opacity-90 cursor-pointer  shadow-lg"
            placeholder=""
            type="profileImage"
          ></UploadFileButton>
        </div>
        <div
          v-for="(item, index) in userFields"
          :key="index"
          class="flex flex-col mb-6"
        >
          <div class="relative">
            <TextField
              v-model="item.model"
              :label="item.label"
              :typeInput="item.type"
              :name="item.name"
            />
          </div>
        </div>
        <div class="flex justify-end">
          <Button
            variant="contained"
            type="submit"
            label="Update information"
            :disabled="!meta.valid || loading"
          />
        </div>
      </form>
    </ReusableModal>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { useForm, useField } from "vee-validate";
import { useStore } from "vuex";
import ReusableModal from "../../components/reusableModal/index.vue";
import TextField from "../../components/textField/index.vue";
import * as yup from "yup";
import { getInitials } from "../../utils/functions";
import { userAPI } from "../../services/user/index";
import Button from "../button/index.vue";
import { createToastAxelerate } from "../../utils/toast";
import UploadFileButton from "@/components/uploadFileButton/index.vue";
import { useState } from "@/composables/useState";

const PersonalDetails = defineComponent({
  name: "PersonalDetails",
  components: {
    ReusableModal,
    UploadFileButton,
    TextField,
    Button,
  },
  setup() {
    const store = useStore();
    const userRes = computed(() => store.state.profile.data);
    const showModal = ref(false);
    const [loading, setLoading] = useState(false);
    const profilePicture = ref();
    const schema = yup.object({
      first_name: yup.string().required().label("First name"),
      last_name: yup.string().required().label("Last name"),
    });

    const { value: firstName } = useField("first_name");
    const { value: lastName } = useField("last_name");
    const { meta, handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: {
        first_name: userRes.value?.first_name,
        last_name: userRes.value?.last_name,
      },
    });

    const userFields = ref([
      {
        name: "first_name",
        model: firstName,
        label: "First name*",
        type: "text",
      },
      { name: "last_name", model: lastName, label: "Last name*", type: "text" },
    ]);
    const nextUpdate = () => {
      store.dispatch("getProfile");
      showModal.value = false;
    };
    const onSubmit = handleSubmit((values) => {
      setLoading(true);
      const data = {
        avatar: profilePicture.value,
        ...values,
      };
      userAPI.updateUser(data, userRes.value?.id).then(() => {
        setLoading(false);
        nextUpdate();
        createToastAxelerate(
          "Personal Info",
          "The info has been updated.",
          "success"
        );
      });
    });
    return {
      showModal,
      userFields,
      onSubmit,
      meta,
      userRes,
      getInitials,
      loading,
      setLoading,
      profilePicture,
    };
  },
});

export default PersonalDetails;
</script>
