
import { useState } from "@/composables/useState";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import BoardCard from "../../components/boardCard/index.vue";
import SkeletonCard from "../../components/skeletonCard/index.vue";
import { JobBoardService } from "../../services/jobBoard/index";

const SimilarJobs = defineComponent({
  name: "SimilarJobs",
  emits: ["reloadSimilarJobs"],
  components: {
    BoardCard,
    SkeletonCard,
  },
  props: {
    company: {
      type: String,
      default: "",
    },
    jobId: {
      type: String,
      default: "",
    },
  },
  setup(props, context) {
    const next = ref(false);
    const router = useRouter();
    const pagination = ref([]);
    const similarJobs = ref([]);
    const previous = ref(false);
    const isLoadingJobs = ref(false);
    const [page, setPage] = useState(1);

    onMounted(() => {
      getSimilarjobs();
    });

    const getSimilarjobs = () => {
      isLoadingJobs.value = true;
      JobBoardService.similarJobs(props.company, props.jobId, page.value)
        .then((response: any) => {
          similarJobs.value = response.data.similar_jobs;
          previous.value = response.data.meta.pagination.prev_page
            ? true
            : false;
          next.value = response.data.meta.pagination.next_page ? true : false;
          pagination.value = [];
          for (
            let index = 0;
            index < response.data.meta.pagination.total_pages;
            index++
          ) {
            pagination.value.push({
              page: index + 1,
            } as never);
          }
          isLoadingJobs.value = false;
        })
        .catch(() => {
          isLoadingJobs.value = false;
        });
    };

    const changePageSimilar = (_page: any) => {
      setPage(_page);
      getSimilarjobs();
    };

    const nextPage = () => {
      if (pagination.value.length > page.value) {
        setPage(page.value + 1);
        getSimilarjobs();
      }
    };

    const prevPage = () => {
      if (page.value > 1) {
        setPage(page.value - 1);
        getSimilarjobs();
      }
    };

    return {
      next,
      page,
      router,
      setPage,
      previous,
      nextPage,
      prevPage,
      pagination,
      similarJobs,
      isLoadingJobs,
      changePageSimilar,
    };
  },
});

export default SimilarJobs;
