
import { defineComponent } from "vue";

const SwitchButton = defineComponent({
  name: "SwitchButton",
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    function updateValue(e: any) {
      emit("update:modelValue", e.target.checked);
    }

    return {
      updateValue,
    };
  },
});

export default SwitchButton;
