
import { computed, defineComponent, onBeforeMount, ref } from "vue";
import TextField from "../../components/textField/index.vue";
import { userAPI } from "../../services/user/index";
import Button from "../../components/button/index.vue";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";
import { createToastAxelerate } from "@/utils/toast";
import { useStore } from "vuex";
import SelectDropdown from "../../components/selectDropdown/index.vue";

export default defineComponent({
  name: "Modal Update Company Info",
  components: {
    TextField,
    Button,
    SelectDropdown,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["close"],
  setup(props, context) {
    const isLoading = ref(false);
    const companyIndustries = ref([]);
    const store = useStore();
    const company = computed(() => store.state.profile.data.company);
    const schema = yup
      .object()
      .shape({
        company_name: yup.string(),
        phone: yup.string(),
        website: yup.string().nullable(),
        company_industry_id: yup.string().nullable(),
      })
      .optional();

    const { value: companyName } = useField("visible_name");
    const { value: website } = useField("website");
    const { value: phone } = useField("phone");
    const { value: company_industry_id } = useField("company_industry_id");
    const { meta, handleSubmit } = useForm({
      validationSchema: schema,
      initialValues: {
        visible_name: company.value.visible_name
          ? company.value.visible_name
          : "",
        website: company.value.website ? company.value.website : "",
        phone: company.value.phone ? company.value.phone : "",
        company_industry_id: company.value.company_industry
          ? company.value.company_industry.id
          : "",
      },
    });

    const userFields = ref([
      { name: "website", model: website, label: "Website", type: "text" },
      { name: "phone", model: phone, label: "Phone number", type: "text" },
    ]);

    onBeforeMount(() => {
      userAPI
        .getCompanyIndustries()
        .then((response: any) => {
          companyIndustries.value = response.data;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Company Industries",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
    });

    const onSubmit = handleSubmit((values) => {
      isLoading.value = true;
      const buildParams = {
        company: {
          ...values,
        },
      };
      userAPI
        .updateCompanyInfo(company.value.id, buildParams)
        .then(async (response) => {
          await store.dispatch("getProfile");
          isLoading.value = false;
          close();
          createToastAxelerate(
            "Update Company Info",
            "Your company info has been updated.",
            "success"
          );
        })
        .catch((err) => {
          isLoading.value = false;
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Update company",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
    });
    const close = () => {
      context.emit("close", false);
    };

    return {
      userFields,
      close,
      meta,
      isLoading,
      onSubmit,
      companyName,
      company_industry_id,
      companyIndustries,
    };
  },
});
