
import { defineComponent } from "vue";

const Skeleton = defineComponent({
  name: "Skeleton",
  props: {
    size: {
      type: String,
      default: "lg",
    },
  },
});
export default Skeleton;
