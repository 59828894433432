
import { defineComponent, ref, computed, Ref, onBeforeMount } from "vue";
import { useStore } from "vuex";
import ModalAddPayment from "@/components/modalAddPayment/index.vue";
import Header from "../../components/header/index.vue";
import Loader from "@/components/loader/index.vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "Home",
  components: {
    Header,
    ModalAddPayment,
    Loader,
  },
  setup() {
    //Variables
    const loading = ref(true);
    const paywall = ref(false);
    //const payments = computed(() => store.getters.getUserPayments);
    const greeting = ref("");
    const name = ref("");
    const store = useStore();
    const services: Ref<Array<string>> = ref([]);
    const products = ref([""]);
    const profile = computed(() => store.state.profile.data);
    const router = useRouter();
    const route = useRoute();
    //Functions
    function calculateGreeting() {
      const now = new Date();
      const hours = now.getHours();
      if (hours >= 5 && hours < 12) {
        greeting.value = "morning";
      } else if (hours >= 12 && hours < 18) {
        greeting.value = "afternoon";
      } else {
        greeting.value = "evening";
      }
    }

    function logout() {
      store.dispatch("logOut");
    }

    function setProductsToShow() {
      for (let i = 0; i < products.value.length; i++) {
        services.value.push(products.value[i]);
      }
    }
    function sendToPlatform(product: string) {
      window.location.replace(
        `https://${product}${process.env.VUE_APP_CANARY_SUFFIX}.axelerate.io`
      );
    }

    function redirectToPlatform() {
      name.value = `${
        store.getters.getUserFirstName + " " + store.getters.getUserLastName
      }`;
      calculateGreeting();
      if (
        role.value.includes("sourcing_admin") &&
        role.value.includes("workforce_admin")
      ) {
        products.value = ["sourcing", "workforce"];
        setProductsToShow();
      } else {
        if (
          role.value.includes("sourcing_admin") ||
          role.value.includes("sourcing_recruiter")
        ) {
          window.location.replace(
            `https://sourcing${process.env.VUE_APP_CANARY_SUFFIX}.axelerate.io`
          );
        } else if (role.value.includes("workforce_admin")) {
          window.location.replace(
            `https://workforce${process.env.VUE_APP_CANARY_SUFFIX}.axelerate.io`
          );
        } else {
          store.dispatch("logOut");
        }
      }
    }
    //Hooks
    const role = computed(() => store.getters.getUserRole);

    onBeforeMount(async () => {
      loading.value = true;
      await store.dispatch("getProfile");

      if (
        route.query.redirect_path !== "" &&
        route.query.redirect_path !== undefined
      ) {
        window.location.replace(String(route.query.redirect_path));
      } else {
        if (
          role.value.includes("internal_recruiter") ||
          role.value.includes("recruiter_admin") ||
          role.value.includes("internal_hr") ||
          role.value.includes("superadmin")
        ) {
          window.location.replace(
            `https://internal${process.env.VUE_APP_CANARY_SUFFIX}.axelerate.io/notifications`
          );
        } else if (role.value.includes("talent")) {
          window.location.replace(process.env.VUE_APP_JSE_URL);
        } else if (
          profile?.value?.managed_jobs?.filter(
            (e: any) => e.subscription_status === "inactive"
          ).length > 0
        ) {
          window.location.replace(
            `https://id${process.env.VUE_APP_CANARY_SUFFIX}.axelerate.io/account-settings/billing`
          );
        } else {
          redirectToPlatform();
        }
      }

      loading.value = false;
    });

    return {
      name,
      services,
      sendToPlatform,
      redirectToPlatform,
      logout,
      paywall,
      role,
      loading,
    };
  },
});
