
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

const CancelPayment = defineComponent({
  name: "CancelPayment",
  setup() {
    const router = useRouter();
    return { router };
  },
});

export default CancelPayment;
