/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { makeFilter } from "@/utils/params";
import { AxiosInstance } from "axios";
import { client } from "../config";
export default class AccountMembersAPIService {
  private axiosInstance: AxiosInstance;
  // private publicInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = client;
  }

  async sendInvite(members: any): Promise<unknown> {
    return this.axiosInstance.post("/account_members", members);
  }
  async getMembers(page = 1, filters: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/account_members?page=${page}&${makeFilter(filters)}`
    );
  }
  async getTaggableMembers(): Promise<unknown> {
    return this.axiosInstance.get("/list_account_members");
  }
  async deleteMember(userId: string): Promise<unknown> {
    return this.axiosInstance.delete(`/account_members/${userId}`);
  }
  async resendInvitation(userId: string): Promise<unknown> {
    return this.axiosInstance.post(`/account_members/${userId}/resend_invite`);
  }
  async getAnalytics(): Promise<unknown> {
    return this.axiosInstance.get("/analytics");
  }
}
export const AccountMembersAPI = new AccountMembersAPIService();
