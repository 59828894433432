import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datepicker = _resolveComponent("Datepicker")!
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Field, {
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      autocomplete: "on",
      focusout: ""
    }, {
      default: _withCtx(({ field }) => [
        _createVNode(_component_Datepicker, {
          modelValue: field.value,
          clearable: true,
          format: _ctx.format,
          autoApply: "",
          closeOnAutoApply: true,
          placeholder: _ctx.placeholder,
          minDate: _ctx.minDate,
          "onUpdate:modelValue": field['onUpdate:modelValue']
        }, null, 8, ["modelValue", "format", "placeholder", "minDate", "onUpdate:modelValue"])
      ]),
      _: 1
    }, 8, ["name", "placeholder"]),
    _createVNode(_component_ErrorMessage, {
      name: _ctx.name,
      class: "text-sm pl-2",
      style: {"color":"#ff3333"},
      id: "error"
    }, null, 8, ["name"])
  ], 64))
}