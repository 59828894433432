
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import VPagination from "@hennge/vue3-pagination";

const Paginator = defineComponent({
  name: "Paginator",
  components: {
    VPagination,
  },
  props: {
    page: {
      type: String,
      default: "",
    },
    pages: {
      type: Number,
      default: 1,
    },
    rangeSize: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    from: {
      type: Number,
      default: 1,
    },
    to: {
      type: Number,
      default: 1,
    },
  },
  model: {
    prop: "modelValue",
    event: "update",
  },
  emits: ["update:modelValue"],
  setup(props) {
    const pagestoSelect = ref([]);
    const pageSelected = ref();
    const rowPerPage = [
      {
        id: 10,
        name: 10,
      },
      {
        id: 20,
        name: 20,
      },
    ];

    onMounted(() => {
      pageSelected.value = props.page;
      fillPageNumbers();
    });

    function fillPageNumbers() {
      let auxiliar = 1;
      pagestoSelect.value = [];
      while (auxiliar <= props.pages) {
        pagestoSelect.value.push({ id: auxiliar, name: auxiliar } as never);
        auxiliar++;
      }
    }

    watchEffect(() => {
      pageSelected.value = props.page;
      fillPageNumbers();
    });

    return {
      pagestoSelect,
      rowPerPage,
      pageSelected,
    };
  },
});

export default Paginator;
