
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

const CompanyCard = defineComponent({
  name: "CompanyCard",

  components: {},
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const router = useRouter();
    const host = process.env.VUE_APP_JOB_BOARD_URL;
    return {
      router,
      host,
    };
  },
});

export default CompanyCard;
