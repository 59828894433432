
/* eslint-disable @typescript-eslint/no-explicit-any */
import { userAPI } from "@/services/user";
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRoute } from "vue-router";
import { useState } from "../../composables/useState";
import { createToastAxelerate } from "../../utils/toast";
import Loader from "../../components/loader/index.vue";
import "mosha-vue-toastify/dist/style.css";

const EmailConfirmation = defineComponent({
  name: "EmailConfirmation",
  components: { Loader },
  setup() {
    const route = useRoute();
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const expired = ref(false);
    const token = ref();
    onBeforeMount(() => {
      setIsLoading(true);
      token.value = route.params.token;
      userAPI
        .emailConfirmation(route.params.token)
        .then((res: any) => {
          setIsLoading(false);
          setMessage(res.data.message);
        })
        .catch((err: any) => {
          if (err.response.status === 403) {
            expired.value = true;
          }
          setIsLoading(false);
          setError(true);
          setMessage(err?.response?.data?.errors[0]);
        });
    });

    const resendEmailConfirmation = () => {
      isLoading.value = true;
      setTimeout(() => {
        userAPI
          .resendEmailConfirmation(token.value)
          .then(async (res: any) => {
            token.value = res.data.token_id;
            createToastAxelerate(
              "Email confirmation",
              "Email has been sent",
              "success"
            );
          })
          .catch(() => {
            createToastAxelerate(
              "Email confirmation",
              "An error has occurred. ",
              "danger"
            );
          });
        isLoading.value = false;
      }, 2000);
    };
    return {
      route,
      message,
      isLoading,
      error,
      expired,
      resendEmailConfirmation,
    };
  },
});

export default EmailConfirmation;
