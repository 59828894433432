<template>
  <div
    v-if="isLoading"
    class="min-h-screen flex flex-col items-center justify-center"
  >
    <Loader />
  </div>
  <div
    class="min-h-screen flex flex-col items-center justify-center rounded bg-background"
    v-if="currentStep !== 2"
  >
    <div
      class="flex flex-col bg-white lg:shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-lg w-full max-w-md"
    >
      <img
        src="@/assets/axelerate-logo-purple.svg"
        alt="login logo"
        class="w-5/12 lg:pb-5"
      />
      <div class="font-bold self-start text-xl sm:text-2xl text-gray">
        {{ currentStep === 0 ? "" : "Just one last step" }}
      </div>
      <div class="mt-10">
        <div id="test">
          <form @submit="nextStep">
            <div v-show="currentStep === 0">
              <div
                v-for="(item, index) in firstFieldValues"
                class="flex flex-col mb-6"
                :key="index"
              >
                <div class="relative">
                  <TextField
                    v-model="item.model"
                    :label="item.label"
                    :typeInput="item.type"
                    :name="item.name"
                  />
                </div>
              </div>
              <div class="flex flex-col mb-6">
                <div class="relative z-0 w-full mb-2">
                  <div class="relative">
                    <PasswordField
                      v-model="formValues.password"
                      label="Password"
                      name="password"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-show="currentStep === 1">
              <div
                v-for="(item, index) in secondFieldValues"
                class="flex flex-col mb-6"
                :key="index"
              >
                <div class="relative">
                  <TextField
                    v-model="item.model"
                    :disabled="item.disabled"
                    :label="item.label"
                    :typeInput="item.type"
                    :name="item.name"
                  />
                </div>
              </div>
              <div class="mb-6">
                <DropDown
                  v-model="industry"
                  :options="companyIndustries"
                  label="Company industry"
                  name="industry"
                />
              </div>
              <div class="mb-6">
                <DropDown
                  v-model="company_country_id"
                  label="Select a country"
                  :options="countries"
                  typeInput="text"
                  name="company_country_id"
                />
              </div>

              <!-- RECAPTCHA -->
              <div
                class="mb-4 flex flex-col justify-center items-center"
                v-if="currentStep === 1"
              >
                <Recaptcha @verify="onSuccess" @error="onError"></Recaptcha>
              </div>
            </div>

            <Button
              :label="`Register`"
              :variant="`contained-full`"
              type="submit"
            />
          </form>
        </div>
      </div>
      <div
        v-if="currentStep === 0"
        class="flex justify-center items-center mt-8 text-base"
      >
        <span>Already have an Axelerate account?</span>
        <router-link to="/login">
          <span
            class="ml-3 inline-flex items-center text-primary font-bold text-base text-centerml-2"
            >Log in</span
          >
        </router-link>
      </div>
    </div>
  </div>
  <div v-if="currentStep === 2 && userEmail !== ''">
    <SignUpSuccess :email="userEmail" :token="confimrationToken" />
  </div>
</template>

<script>
import { useField, useForm } from "vee-validate";
import * as yup from "yup";
import {
  ref,
  reactive,
  defineComponent,
  computed,
  onBeforeMount,
  onMounted,
} from "vue";
import TextField from "../../components/textField/index.vue";
import PasswordField from "../../components/passwordField/index.vue";
import { userAPI } from "../../services/user";
import { createToastAxelerate } from "../../utils/toast";
import DropDown from "../../components/selectDropdown";
import SignUpSuccess from "../../views/sign-up-success";
import Button from "../../components/button/index.vue";
import Loader from "../../components/loader/index.vue";
import { useStore } from "vuex";
import Recaptcha from "@/components/recaptcha/index.vue";
// import vSelect from "vue-select";
// import 'vue-select/dist/vue-select.css';
import { CountriesAPI } from "@/services/countries";
import { useRoute, useRouter } from "vue-router";

const SignUp = defineComponent({
  name: "SignUp",
  components: {
    TextField,
    PasswordField,
    DropDown,
    SignUpSuccess,
    Button,
    Loader,
    Recaptcha,
  },
  setup: () => {
    const router = useRouter();
    const route = useRoute();
    const currentStep = ref(0);
    const userEmail = ref("");
    const confimrationToken = ref("");
    const formValues = reactive({});
    const isLoading = ref(false);
    const store = useStore();
    const companyIndustries = ref([]);
    const recaptchaToken = ref("");
    const countries = ref();
    const { value: firstName } = useField("first_name");
    const { value: lastName } = useField("last_name");
    const { value: email } = useField("email");
    const { value: company } = useField("company_name");
    const { value: website } = useField("website");
    const { value: phone } = useField("phone");
    const { value: company_country_id } = useField("company_country_id");
    const { value: industry } = useField("industry");

    const firstFieldValues = ref([
      {
        name: "first_name",
        label: "First name",
        type: "text",
        model: firstName,
      },
      { name: "last_name", label: "Last name", type: "text", model: lastName },
      { name: "email", label: "Email", type: "email", model: email },
    ]);
    const secondFieldValues = ref([
      {
        name: "company_name",
        label: "Company name",
        type: "text",
        disabled: false,
        model: company,
      },
      {
        name: "website",
        label: "Company website",
        type: "text",
        disabled: false,
        model: website,
      },
      {
        name: "phone",
        label: "Phone number (Optional)",
        type: "tel",
        disabled: false,
        model: phone,
      },
    ]);

    const schemas = [
      yup.object({
        first_name: yup.string().required().max(50).min(3).label("First name"),
        last_name: yup.string().required().max(50).min(3).label("Last name"),
        email: yup.string().required().email(),
        password: yup
          .string()
          .required()
          .matches(
            /[0-9A-Za-z]*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?][0-9a-zA-Z]*$/,
            "Must contain at least one uppercase, special character and number"
          ),
      }),
      yup.object({
        company_name: yup
          .string()
          .required()
          .max(50)
          .min(3)
          .label("Visible name"),
        website: yup
          .string()
          .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter a correct url"
          )
          .required(),
        industry: yup.string().required().label("Company industry"),
        phone: yup.string().nullable(),
        company_country_id: yup.string().required().label("company_country_id"),
      }),
    ];

    const currentSchema = computed(() => {
      return schemas[currentStep.value];
    });

    const { handleSubmit } = useForm({
      validationSchema: currentSchema,
    });
    const nextStep = handleSubmit((values) => {
      if (
        currentStep.value === 1 &&
        route.params.accountType === "businesses"
      ) {
        if (recaptchaToken.value) {
          isLoading.value = true;
          Object.assign(formValues, values);
          const userPayload = {
            token: recaptchaToken.value,
            user: {
              ...formValues,
              company_industry_id: values.industry,
              roles: ["sourcing_admin"],
              products: ["sourcing"],
              password_confirmation: formValues.password,
            },
          };
          const loginData = {
            email: userPayload.user.email,
            password: userPayload.user.password,
          };
          userAPI
            .signUp(userPayload)
            .then(async (res) => {
              await logInUser(loginData);
              createToastAxelerate(
                "Success!",
                "Account created successfully",
                "success"
              );
            })
            .catch((err) => {
              const {
                response: {
                  data: { errors },
                },
              } = err;
              createToastAxelerate("Sign Up", errors[0], "danger");
              isLoading.value = false;
            });
          return;
        } else {
          createToastAxelerate(
            "Sign up",
            "you must validate the reCAPTCHA",
            "warning"
          );
        }
      } else if (route.params.accountType === "talent") {
        const body = {
          talent: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            password: values.password,
            password_confirmation: values.password,
          },
        };
        userAPI
          .signUpTalent(body)
          .then(async () => {
            const loginValues = {
              email: values.email,
              password: values.password,
            };
            await logInUser(loginValues);
          })
          .catch((err) => {
            let txt = null;
            const {
              response: {
                data: { errors },
              },
            } = err;
            if (errors?.Email) {
              txt = "Email " + errors.Email[0];
            }
            createToastAxelerate(
              "Something went wrong",
              (txt = txt ? txt : "Please try again"),
              "danger"
            );
          });
      } else {
        Object.assign(formValues, values);
        currentStep.value++;
      }
    });

    const prevStep = () => {
      if (currentStep.value <= 0) {
        return;
      }
      currentStep.value--;
    };

    const logInUser = async (userData) => {
      store.dispatch("loginUser", userData);
    };

    onBeforeMount(() => {
      userAPI
        .getCompanyIndustries()
        .then((response) => {
          companyIndustries.value = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    });

    onMounted(() => {
      CountriesAPI.index()
        .then(async (res) => {
          countries.value = res.data;
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
        });
    });

    const onSuccess = (token) => {
      recaptchaToken.value = token;
    };

    const onError = (event) => {
      createToastAxelerate("Recaptcha", "Something went wrong", "danger");
    };

    return {
      currentStep,
      firstFieldValues,
      secondFieldValues,
      currentSchema,
      prevStep,
      formValues,
      nextStep,
      countries,
      company_country_id,
      userEmail,
      confimrationToken,
      isLoading,
      industry,
      companyIndustries,
      onSuccess,
      onError,
      recaptchaToken,
    };
  },
});
export default SignUp;
</script>
