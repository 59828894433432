<template>
  <div class="flex flex-wrap justify-start items-start space-x-1">
    <div
      class="flex flex-row space-x-2 py-1"
      v-for="(chip, i) in chips"
      :key="i"
    >
      <span
        class="bg-primary-light text-primary rounded-lg px-3 py-2 flex flex-row justify-between items-center font-medium"
        >{{ chip }}
        <svg
          @click="deleteChip(i)"
          class="h-5 w-5 ml-4 text-primary cursor-pointer transition-all 2s"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" /></svg
      ></span>
    </div>
    <div
      class="relative flex flex-row justify-between items-center rounded-2xl"
    >
      <input
        :class="isInput ? 'block' : 'hidden'"
        class="w-40 pr-10 pl-1 py-1 font-medium focus:ring-0 focus:outline-transparent focus:outline-none border-b-2 mt-2"
        autofocus
        :placeholder="placeholder"
        v-model="currentInput"
        @keypress="disabledEnter"
      />
      <svg
        @click="saveChip"
        class="h-6 w-6 border p-1 rounded-full absolute right-2 bg-primary text-white cursor-pointer"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="3"
          d="M12 4v16m8-8H4"
        />
      </svg>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import { defineComponent, ref } from "vue";
import { hexToRGBA } from "@/utils/functions";

const Chips = defineComponent({
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    set: {
      type: Boolean,
      default: true,
    },
    chips: {
      type: Array,
      required: true,
    },
    isInput: {
      type: Boolean,
      default: true,
    },
    primaryColor: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const currentInput = ref("");
    const saveChip = () => {
      ((props.set && props.chips.indexOf(currentInput.value) === -1) ||
        !props.set) &&
        props.chips.push(currentInput.value);
      currentInput.value = "";
    };
    const deleteChip = (index) => {
      props.chips.splice(index, 1);
    };
    const disabledEnter = (e) => {
      if (e.which === 13) {
        saveChip();
      }
    };
    const backspaceDelete = ({ which }) => {
      which == 8 &&
        currentInput.value === "" &&
        props.chips.splice(this.chips.length - 1);
    };
    const addInput = (e) => {
      if (e.key === "Enter") {
        saveChip();
      }
    };
    return {
      saveChip,
      deleteChip,
      disabledEnter,
      backspaceDelete,
      currentInput,
      addInput,
      hexToRGBA,
    };
  },
});
export default Chips;
</script>
<style scoped>
.chip-container {
  min-height: 34px;
}
input:focus {
  border-color: #f5ebfe;
}
</style>
