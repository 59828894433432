import { AxiosInstance } from "axios";
import { makeFilter } from "@/utils/params";
import { client } from "../config";

class JobBoardServiceAPI {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = client;
  }

  async getCompany(
    company_name: string,
    page: number,
    filters: any
  ): Promise<unknown> {
    return this.axiosInstance.get(
      `/job_board/${company_name}?page=${page}&${makeFilter(filters)}`
    );
  }

  async getJobByCompany(
    company_name: string,
    job_id: string
  ): Promise<unknown> {
    return this.axiosInstance.get(`/job_board/${company_name}/${job_id}`);
  }

  async getCompanyById(company_id: string): Promise<unknown> {
    return this.axiosInstance.get(`/companies/${company_id}/company_brandings`);
  }

  async applyJob(
    company_name: string,
    job_id: string,
    data: any
  ): Promise<unknown> {
    const formData = new FormData();
    formData.append("candidate[first_name]", data.candidate.first_name);
    formData.append("candidate[last_name]", data.candidate.last_name);
    formData.append("candidate[position]", data.candidate.position); //jse cj : aplication :
    formData.append("candidate[email]", data.candidate.email);
    formData.append("candidate[place_id]", data.candidate.place_id);
    formData.append("candidate[phone]", data.candidate.phone);
    formData.append("candidate[summary]", data.candidate.summary);
    formData.append("candidate[application]", data.candidate.application);
    formData.append(
      "candidate[salary_currency]",
      data.candidate.salary_currency
    );
    formData.append("candidate[address]", data.candidate.address);
    formData.append(
      "candidate[salary_expectation]",
      data.candidate.salary_expectation
    );
    formData.append("candidate[current_salary]", data.candidate.current_salary);
    formData.append(
      "candidate[current_salary_currency]",
      data.candidate.current_salary_currency
    );
    formData.append("candidate[resume]", data.candidate.resume);

    return this.axiosInstance.post(
      `/job_board/${company_name}/${job_id}/apply`,
      formData
    );
  }

  async updateCandidate(
    company_name: string,
    job_id: string,
    data: any,
    candidate_id: string
  ) {
    return this.axiosInstance.put(
      `/job_board/apply_update/${candidate_id}`,
      data
    );
  }

  async getGeneralJobs(page: any, filters: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/job_board/jobs?page=${page}&${makeFilter(filters)}`
    );
  }

  async getCompanies(page: any, filters: any): Promise<unknown> {
    return this.axiosInstance.get(
      `/job_board/companies?page=${page}&${makeFilter(filters)}`
    );
  }
  async similarJobs(
    company: string,
    jobId: string,
    page: any
  ): Promise<unknown> {
    return this.axiosInstance.get(
      `/job_board/${company}/${jobId}/similar_jobs?page=${page}`
    );
  }

  async updateCompanyBranding(
    companyId: string,
    companyBrandingId: string,
    data: any
  ): Promise<unknown> {
    const formData = new FormData();
    if (data.logo) formData.append("company_branding[logo]", data.logo);
    if (data.background)
      formData.append("company_branding[background]", data.background);
    formData.append("company_branding[description]", data.description);
    if (data.primary_color)
      formData.append("company_branding[primary_color]", data.primary_color);
    if (data.secondary_color)
      formData.append(
        "company_branding[secondary_color]",
        data.secondary_color
      );
    if (data.site_url)
      formData.append("company_branding[site_url]", data.site_url);
    if (data.domain_name)
      formData.append("company_branding[domain_name]", data.domain_name);

    return this.axiosInstance.put(
      `/companies/${companyId}/company_brandings/${companyBrandingId}`,
      formData
    );
  }

  async getCompanyBranding(
    companyId: string,
    companyBrandingId: string
  ): Promise<unknown> {
    return this.axiosInstance.get(
      `/companies/${companyId}/company_brandings/${companyBrandingId}`
    );
  }

  async applyJobSignedTalent(
    company: string,
    jobId: string,
    candidateId: string
  ): Promise<unknown> {
    return this.axiosInstance.post(
      `/job_board/${company}/${jobId}/apply/${candidateId}?application=jse` // application '' : ''
    );
  }

  async getCompanyIndustries(): Promise<unknown> {
    return this.axiosInstance.get("/company_industries");
  }

  async getSkills(): Promise<unknown> {
    return this.axiosInstance.get("/skills");
  }
}

export const JobBoardService = new JobBoardServiceAPI();
