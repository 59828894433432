
import { defineComponent, ref, computed } from "vue";
//import EmailChips from "../emailChips/index.vue";
import { AccountMembersAPI } from "@/services/accountMembers";
import { createToastAxelerate } from "../../utils/toast";
//import Button from "../button/index.vue";
// import CheckBoxGroup from "../checkboxGroup/index.vue";
import { useStore } from "vuex";
import InvitesList from "@/components/invitesList/index.vue";

const InviteMember = defineComponent({
  name: "InviteMember",
  emits: ["closeModal", "reloadMembers"],
  components: {
    //EmailChips,
    InvitesList,
    //Button,
  },
  setup(props, context) {
    const emails = ref([]);
    const checkValues = ref([]);
    const store = useStore();
    const roles = computed(() => store.state.profile.data.roles);
    const handleSubmit = () => {
      const buildPayload = {
        account_members: emails.value,
        roles: ["sourcing_admin"],
      };
      AccountMembersAPI.sendInvite(buildPayload)
        .then(async () => {
          createToastAxelerate(
            "Invite Members",
            "The invitation has been send.",
            "success"
          );
          context.emit("closeModal");
          await store.dispatch("getProfile");
          context.emit("reloadMembers");
        })
        .catch((err) => {
          const {
            response: {
              data: { errors },
            },
          } = err;
          createToastAxelerate(
            "Invite Members",
            errors ? errors[0] : "An error has ocurred.",
            "danger"
          );
        });
    };
    return { emails, handleSubmit, checkValues, roles };
  },
});

export default InviteMember;
